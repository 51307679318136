import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CookService } from 'src/app/services/cook.service';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.css']
})
export class AgbComponent implements OnInit {

  constructor(public translate: TranslateService, public cookie: CookService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.translate.use('de');
    this.cookie.setCookie('lang','de');
  }

}
