import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CookService } from 'src/app/services/cook.service';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})
export class DatenschutzComponent implements OnInit {

  constructor(public translate: TranslateService, public cookie: CookService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.translate.use('de');
    this.cookie.setCookie('lang','de');
  }

}
