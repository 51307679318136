
    
      <!-- Page-->
      <div class="page">
        <app-header></app-header>
        
        <!-- Swiper-->
        <section>
          <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
            data-loop="true" data-autoplay="5000">
            <div class="swiper-wrapper">
              <div class="swiper-slide" data-slide-bg="assets/images/slider-slide-1-1920x1080.jpg">
                <div class="swiper-slide-caption text-left">
                  <div class="container">
                    <div class="row justify-content-center justify-content-xxl-start">
                      <div class="col-lg-10 col-xxl-6">
                        <h1 data-caption-animate="fadeInUpSmall">{{ 'header.slider.slide1.title' | translate }}</h1>
                        <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">{{ 'header.slider.slide1.caption' | translate }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide text-center swiper-slide-overlay"
                data-slide-bg="assets/images/slider-slide-2-1920x1080.jpg">
                <div class="swiper-slide-caption">
                  <div class="container">
                    <div class="row justify-content-lg-center">
                      <div class="col-lg-10 col-xxl-6">
                        <h2 data-caption-animate="fadeInUpSmall">{{ 'header.slider.slide2.title' | translate }}</h2>
                        <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">{{ 'header.slider.slide2.caption' | translate }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide text-right swiper-slide-overlay" data-slide-bg="assets/images/slider-slide-3-1920x1080.jpg">
                <div class="swiper-slide-caption">
                  <div class="container">
                    <div class="row justify-content-center justify-content-xxl-end">
                      <div class="col-lg-10 col-xxl-6">
                        <h2 data-caption-animate="fadeInUpSmall">{{ 'header.slider.slide3.title' | translate }}</h2>
                        <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">{{ 'header.slider.slide3.caption' | translate }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Swiper Pagination-->
            <div class="swiper-pagination"></div>
            <!-- Swiper Navigation-->
            <div class="swiper-button-prev linear-icon-chevron-left"></div>
            <div class="swiper-button-next linear-icon-chevron-right"></div>
          </div>
        </section>
    
        <!-- Orange line under slider -->
        <section class="section-xs bg-accent"></section>
        <!-- Impressive Features-->
    
    
        <!-- About us-->
        <section class="section-xs bg-default object-wrap aboutus-margin">
          <div class="section-lg">
            <div class="container">
              <div class="row">
    
                <div class="col-lg-5">
                  <h4 class="heading-decorated">{{ 'home.about.title' | translate }}</h4>
                  <p>{{ 'home.about.para1' | translate }}</p>
                    <h6><p class="fontbold">{{ 'home.about.subtitle' | translate }}</p></h6>
                    <p>{{ 'home.about.para2' | translate }}</p>
                  </div>
    
              </div>
            </div>
          </div>
          <div class="object-wrap__body object-wrap__body-sizing-1 object-wrap__body-md-right bg-image aboutus-bg-image"></div>
        </section>
    
        <!-- Projects-->
        <section class="bg-default text-center">
          <div class="container">
              <img class="post-project__image" src="assets/images/Logo_Separator.png" alt="{{ 'home.SEO.alt.logo-sep' | translate }}" />
            <h4 class="orange">{{ 'home.portfolio.title' | translate }}</h4>
            <div class="row row-50">
              <div class="col-md-6 col-xl-4">
                <!-- Post project-->
                <article class="post-project"><a class="img-thumbnail-variant-1" href="3d-druck/">
                    <figure><img class="post-project__image" src="assets/images/3d-druckdienst-additive-fertigung.jpg" alt="{{ 'home.SEO.alt.project1' | translate }}" width="652"
                        height="591" />
                    </figure>
                  </a>
                  <div class="post-project__body">
                    <h6 class="post-project__title"><a href="3d-druck/">{{ 'home.portfolio.article1.title' | translate }}</a></h6>
                    <p>{{ 'home.portfolio.article1.desc' | translate }}</p><a class="button button-link" href="3d-druck/">{{ 'home.portfolio.buttons' | translate }}</a>
                  </div>
                </article>
              </div>
              <div class="col-md-6 col-xl-4">
                <!-- Post project-->
                <article class="post-project"><a class="img-thumbnail-variant-1" href="Auftragsfertigung/">
                    <figure><img class="post-project__image" src="assets/images/auftragsfertigung-lohnfertigung.jpg" alt="{{ 'home.SEO.alt.project2' | translate }}" width="652"
                        height="491" />
                    </figure>
                  </a>
                  <div class="post-project__body">
                    <h6 class="post-project__title"><a href="Auftragsfertigung/">{{ 'home.portfolio.article2.title' | translate }}</a></h6>
                    <p>{{ 'home.portfolio.article2.desc' | translate }}</p><a class="button button-link"
                      href="Auftragsfertigung/">{{ 'home.portfolio.buttons' | translate }}</a>
                  </div>
                </article>
              </div>
              <div class="col-md-6 col-xl-4">
                <!-- Post project-->
                <article class="post-project"><a class="img-thumbnail-variant-1" href="Augmented-Reality/">
                    <figure><img class="post-project__image" src="assets/images/augmented-reality-ar.jpg" alt="{{ 'home.SEO.alt.project3' | translate }}" width="652"
                        height="491" />
                    </figure>
                  </a>
                  <div class="post-project__body">
                    <h6 class="post-project__title"><a href="Augmented-Reality/">{{ 'home.portfolio.article3.title' | translate }}</a></h6>
                    <p>{{ 'home.portfolio.article3.desc' | translate }}</p><a class="button button-link"
                      href="Augmented-Reality/">{{ 'home.portfolio.buttons' | translate }}</a>
                  </div>
                </article>
              </div>
              <div class="col-md-6 col-xl-4">
                <!-- Post project-->
                <article class="post-project"><a class="img-thumbnail-variant-1" href="Virtual-Reality/">
                    <figure><img class="post-project__image" src="assets/images/virtual-reality-vr.jpg" alt="{{ 'home.SEO.alt.project4' | translate }}" width="652"
                        height="491" />
                    </figure>
                  </a>
                  <div class="post-project__body">
                    <h6 class="post-project__title"><a href="Virtual-Reality/">{{ 'home.portfolio.article4.title' | translate }}</a></h6>
                    <p>{{ 'home.portfolio.article4.desc' | translate }}</p><a class="button button-link"
                      href="Virtual-Reality/">{{ 'home.portfolio.buttons' | translate }}</a>
                  </div>
                </article>
              </div>
              <div class="col-md-6 col-xl-4">
                <!-- Post project-->
                <article class="post-project"><a class="img-thumbnail-variant-1" href="Architekturleistungen/">
                    <figure><img class="post-project__image" src="assets/images/architekturdienstleistungen-aus-einer-hand.jpg" alt="{{ 'home.SEO.alt.project5' | translate }}" width="652"
                        height="491" />
                    </figure>
                  </a>
                  <div class="post-project__body">
                    <h6 class="post-project__title"><a href="Architekturleistungen/">{{ 'home.portfolio.article5.title' | translate }}</a></h6>
                    <p>{{ 'home.portfolio.article5.desc' | translate }}</p><a class="button button-link"
                      href="Architekturleistungen/">{{ 'home.portfolio.buttons' | translate }}</a>
                  </div>
                </article>
              </div>
              <div class="col-md-6 col-xl-4">
                <!-- Post project-->
                <article class="post-project"><a class="img-thumbnail-variant-1" href="Produktentwicklung/">
                    <figure><img class="post-project__image" src="assets/images/outsourcing-ingenieurdienstleistungen-aus-indien.jpg" alt="{{ 'home.SEO.alt.project6' | translate }}" width="652"
                        height="491" />
                    </figure>
                  </a>
                  <div class="post-project__body">
                    <h6 class="post-project__title"><a href="Produktentwicklung/">{{ 'home.portfolio.article6.title' | translate }}</a></h6>
                    <p>{{ 'home.portfolio.article6.desc' | translate }}</p><a
                      class="button button-link" href="Produktentwicklung/">{{ 'home.portfolio.buttons' | translate }}</a>
                  </div>
                </article>
              </div>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </section>
    
        <!--cta-->
        <!-- Call to Action-->
        <section class="section-md  bg-image text-center cta-bg-image">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-md-11 col-lg-9 col-xl-8">
                <h4 class="heading-decorated whitecolor">{{ 'home.cloudBanner.title' | translate }}</h4><a class="button button-primary" href="Kontakt/Startseite/">{{ 'home.cloudBanner.button' | translate }}</a>
              </div>
            </div>
          </div>
        </section>
    
    
    
        <!--testimonials-->
        <section class="section-lg bg-image text-center" >
          <div class="container blackcolor">
            <h4 class="heading-decorated blackcolor">{{ 'home.testimonial.header' | translate }}</h4>
            <!-- Owl Carousel-->
            <div class="owl-carousel" data-items="2" data-stage-padding="15" data-loop="true" data-margin="30"
              data-dots="true" data-nav="true">
              <div class="item">
                <!-- Quote default-->
                <div class="quote-default">
                  
                  <div class="quote-default__text">
                    <p class="fontbold">{{ 'home.testimonial.item1.title' | translate }}</p>
                    <p class="q">{{ 'home.testimonial.item1.desc' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="item">
                <!-- Quote default-->
                <div class="quote-default">
                  
                  <div class="quote-default__text">
                      <p class="fontbold">{{ 'home.testimonial.item2.title' | translate }}</p>
                      <p class="q">{{ 'home.testimonial.item2.desc' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="item">
                <!-- Quote default-->
                <div class="quote-default">
                  
                  <div class="quote-default__text">
                      <p class="fontbold">{{ 'home.testimonial.item3.title' | translate }}</p>
                      <p class="q">{{ 'home.testimonial.item3.desc' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="item">
                  <!-- Quote default-->
                  <div class="quote-default">
                    
                    <div class="quote-default__text">
                        <p class="fontbold">{{ 'home.testimonial.item4.title' | translate }}</p>
                        <p class="q">{{ 'home.testimonial.item4.desc' | translate }}</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>
    
        <app-footer></app-footer>
      </div>
    
    

    
    
      <!-- 
     
  
      -->
  
  