<div class="page">
  <app-header></app-header>
  <!-- Swiper-->
  <section>
    <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
      data-loop="false" data-autoplay="5000">
      <div class="swiper-wrapper">
        <div class="swiper-slide" data-slide-bg="assets/images/page-kleinserienfertigung/Header.jpg">
          <div class="swiper-slide-caption text-left">
            <div class="container">
              <div class="row justify-content-center justify-content-xxl-start">
                <div class="col-lg-10 col-xxl-6">
                  <h1 data-caption-animate="fadeInUpSmall">{{ 'auftrag.slider.title' | translate }}</h1>
                  <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">
                    {{ 'auftrag.slider.subtitle' | translate }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>

  <!-- Orange line under slider -->
  <section class="section-xs bg-accent"></section>
  <!-- Impressive Features-->


  <!-- Augmented Reality -->
  <section class="section-xs bg-default object-wrap">
    <div class="section-md">
      <div class="container">
        <div class="row">

          <div class="col-md-6">
            <h4 class="heading-decorated">{{ 'auftrag.block1.title' | translate }}</h4>
            <p>{{ 'auftrag.block1.paraLeft.text1' | translate }}<a
                class="orange" href="Produktentwicklung">{{ 'auftrag.block1.paraLeft.orange1' | translate }}</a>{{ 'auftrag.block1.paraLeft.text2' | translate }}
            </p>
          </div>

          <div class="col-md-6">
            <h4 class="heading-decorated-blank">&nbsp;</h4>
            <p>{{ 'auftrag.block1.paraRight.text1' | translate }}<a href="Kontakt/Auftragsfertigung/"
                class="orange">{{ 'auftrag.block1.paraRight.anchor' | translate }}</a></p>
          </div>
        </div>
      </div>
    </div>

  </section>

  <!-- UNSERE LEISTUNGEN IM ÜBERBLICK -->
  <section class="bg-default object-wrap">
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h4 class="heading-decorated">{{ 'auftrag.block2.title' | translate }}</h4>
            <ul class="c">
              <li><p>&#9673;&nbsp;&nbsp;{{ 'auftrag.block2.paraLeft.text1' | translate }}</p></li>
              <li><p>&#9673;&nbsp;&nbsp;{{ 'auftrag.block2.paraLeft.text2' | translate }}</p></li>
              <li><p>&#9673;&nbsp;&nbsp;{{ 'auftrag.block2.paraLeft.text3' | translate }}</p></li>
            </ul>
          </div>
          <div class="col-lg-6">

            <div class="col-md-12">
              <!-- Post project-->
              <article class="post-project">



                <figure>
                  <video controls autoplay="autoplay" loop width="530" height="350">
                    <source src="assets/images/page-kleinserienfertigung/auftragsfertigung-mit-low-cost-vorteilen.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>

                </figure>

              </article>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-default object-wrap">
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xl-12">
            <div class="text-center col-md-12 col-xl-12">
              <div>&nbsp;</div>
              <img class="post-project__image" src="assets/images/Logo_Separator.png" alt="{{ 'home.SEO.alt.logo-sep' | translate }}" />
              <h4 class="orange">{{ 'auftrag.block3.title' | translate }}</h4>
              <p>&nbsp;</p>
            </div>

            <div class="container klein-container-border">
              <div class="row klein-padding-4">

                <div class="col-lg-6">

                  <div class="col-md-12">
                    <!-- Post project-->
                    <article class="post-project">

                      <figure>
                        <img class="post-project__image" src="assets/images/page-kleinserienfertigung/sandguss-metallguss-fuer-kleinserien.jpg"
                        alt="{{ 'auftrag.SEO.alt.block1' | translate }}" />
                      </figure>

                    </article>
                  </div>


                </div>

                <div class="col-lg-6">
                  <h5 class="">{{ 'auftrag.block3.block1.title' | translate }}</h5>
                  <ul class="b">
                    <li>
                      <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block1.item1' | translate }}</p>
                    </li>
                    <li>
                      <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block1.item2' | translate }}</p>
                    </li>
                    <li>
                      <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block1.item3' | translate }}</p>
                    </li>
                    <li>
                      <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block1.item4' | translate }}</p>
                    </li>
                    <li>
                      <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block1.item5' | translate }}</p>
                    </li>
                  </ul>
                  <a
                      href="Kontakt/Auftragsfertigung/" class="orange klein-link">{{ 'auftrag.block3.block1.anchor' | translate }}</a>
                </div>
              </div>
              <div class="row">

                <div class="col-lg-6">

                  <div class="col-md-12">
                    <!-- Post project-->
                    <article class="post-project">

                      <figure>
                        <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-spritzguss-fuer-kleinserien.jpg"
                        alt="{{ 'auftrag.SEO.alt.block2' | translate }}" />
                      </figure>

                    </article>
                  </div>


                </div>

                <div class="col-lg-6" >
                    <h5 class="">{{ 'auftrag.block3.block2.title' | translate }}</h5>
                    <ul class="b">
                      <li>
                        <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block2.item1' | translate }}</p>
                      </li>
                      <li>
                        <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block2.item2' | translate }}</p>
                      </li>
                      <li>
                        <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block2.item3' | translate }}</p>
                      </li>
                      <li>
                        <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block2.item4' | translate }}</p>
                      </li>
                      <li>
                        <p>&#9632;&nbsp;&nbsp;&nbsp;{{ 'auftrag.block3.block2.item5' | translate }}</p>
                      </li>
                    </ul>
                    <a
                        href="Kontakt/Auftragsfertigung/" class="orange klein-link">{{ 'auftrag.block3.block2.anchor' | translate }}</a>
                  </div>
              </div>
              <p>&nbsp;</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <!--image block-->
  <section class="section-xs bg-default text-center">
    <div class="container">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h4 class="orange">{{ 'auftrag.block4.title' | translate }}</h4>
      <div class="row">
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-1.jpg" alt="{{ 'auftrag.SEO.alt.thumb1' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item1.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item1.desc' | translate }}</p>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-2.jpg" alt="{{ 'auftrag.SEO.alt.thumb2' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item2.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item2.desc' | translate }}</p>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-3.jpg" alt="{{ 'auftrag.SEO.alt.thumb3' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item3.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item3.desc' | translate }}</p>
            </figure>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-4.jpg" alt="{{ 'auftrag.SEO.alt.thumb4' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item4.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item4.desc' | translate }}</p>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-5.jpg" alt="{{ 'auftrag.SEO.alt.thumb5' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item5.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item5.desc' | translate }}</p>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-6.jpg" alt="{{ 'auftrag.SEO.alt.thumb6' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item6.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item6.desc' | translate }}</p>
            </figure>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-7.jpg" alt="{{ 'auftrag.SEO.alt.thumb7' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item7.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item7.desc' | translate }}</p>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-8.jpg" alt="{{ 'auftrag.SEO.alt.thumb8' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item8.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item8.desc' | translate }}</p>
            </figure>
          </div>
        </div>
        <div class="col-md-4 col-xl-4">
          <div class="item">
            <figure>
              <img class="post-project__image" src="assets/images/page-kleinserienfertigung/vakuumguss-prinzip-erklaerung-9.jpg" alt="{{ 'auftrag.SEO.alt.thumb9' | translate }}" />
              <span>&nbsp;</span>
              <h5 class="orange">{{ 'auftrag.block4.item9.title' | translate }}</h5>
              <p>{{ 'auftrag.block4.item9.desc' | translate }}</p>
            </figure>
          </div>
        </div>
      </div>
      <h4>&nbsp;</h4>
      <h4>&nbsp;</h4>
    </div>

  </section>


  <!-- Page Footer -->
  <app-footer></app-footer>
</div>