import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { isNull } from 'util';
import { CookService } from '../services/cook.service';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css'],

})
export class KontaktComponent implements OnInit {
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';

  public lang_captcha = 'de';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;
  public readonly siteKey = '6LdO8voUAAAAACvZe_aec57WVoLhDa5bLfW3cIeW';

  referralPage: string = 'ttes';
  formLoad = false;
  formResult = true;
  isDisabled = true;
  submitted = false;
  windowDefined = false;

  profile = new FormGroup({
    sex: new FormControl(''),
    fname: new FormControl('', Validators.required),
    lname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    firma: new FormControl(''),
    mainCat: new FormGroup({
      threed: new FormControl(''),
      auftrag: new FormControl(''),
      ar: new FormControl(''),
      vr: new FormControl(''),
      archi: new FormControl(''),
      ingeni: new FormControl(''),
      prod: new FormControl(''),
      others: new FormControl('')
    }),
    subCat: new FormGroup({
      ck_1_1: new FormControl({ value: '', disabled: true }),
      ck_1_2: new FormControl({ value: '', disabled: true }),
      ck_1_3: new FormControl({ value: '', disabled: true }),
      ck_1_4: new FormControl({ value: '', disabled: true }),
      ck_1_5: new FormControl({ value: '', disabled: true }),

      ck_2_1: new FormControl({ value: '', disabled: true }),
      ck_2_2: new FormControl({ value: '', disabled: true }),
      ck_2_3: new FormControl({ value: '', disabled: true }),
      ck_2_4: new FormControl({ value: '', disabled: true }),
      ck_2_5: new FormControl({ value: '', disabled: true }),
      ck_2_6: new FormControl({ value: '', disabled: true }),
      ck_2_7: new FormControl({ value: '', disabled: true }),

      ck_3_1: new FormControl({ value: '', disabled: true }),
      ck_3_2: new FormControl({ value: '', disabled: true }),
      ck_3_3: new FormControl({ value: '', disabled: true }),
      ck_3_4: new FormControl({ value: '', disabled: true }),

      ck_4_1: new FormControl({ value: '', disabled: true }),
      ck_4_2: new FormControl({ value: '', disabled: true }),
      ck_4_3: new FormControl({ value: '', disabled: true }),

      ck_5_1: new FormControl({ value: '', disabled: true }),
      ck_5_2: new FormControl({ value: '', disabled: true }),
      ck_5_3: new FormControl({ value: '', disabled: true }),
      ck_5_4: new FormControl({ value: '', disabled: true }),
      ck_5_5: new FormControl({ value: '', disabled: true }),

      ck_6_1: new FormControl({ value: '', disabled: true }),
      ck_6_2: new FormControl({ value: '', disabled: true }),
      ck_6_3: new FormControl({ value: '', disabled: true }),
      ck_6_4: new FormControl({ value: '', disabled: true }),
      ck_6_5: new FormControl({ value: '', disabled: true }),

      ck_7_1: new FormControl({ value: '', disabled: true }),
      ck_7_2: new FormControl({ value: '', disabled: true }),
      ck_7_3: new FormControl({ value: '', disabled: true }),
      ck_7_4: new FormControl({ value: '', disabled: true }),
      ck_7_5: new FormControl({ value: '', disabled: true }),

      ck_8_1: new FormControl({ value: '', disabled: true }),
      ck_8_2: new FormControl({ value: '', disabled: true }),
      ck_8_3: new FormControl({ value: '', disabled: true }),
      ck_8_4: new FormControl({ value: '', disabled: true }),
      ck_8_5: new FormControl({ value: '', disabled: true })

    }),
    message: new FormControl(''),
    fileUpl: new FormControl(''),
    datenschutz: new FormControl('', Validators.required),
    recaptcha: new FormControl('', Validators.required),
    referralLink: new FormControl('')
  })




  httpClient: any;

  get f() {
    return this.profile.controls;
  }

  resetValues() {

  }


  constructor(@Inject(PLATFORM_ID) private platformID, private route: ActivatedRoute, public translate: TranslateService, public cookie: CookService, private http: HttpClient) {
    if (isPlatformBrowser(this.platformID)) {
      this.windowDefined = true;
    }
    this.formLoad = false;
    this.formResult = true;

  }

  ct_1 = 5; ck_1_1 = true; ck_1_2 = true; ck_1_3 = true; ck_1_4 = true; ck_1_5 = true;
  ct_2 = 7; ck_2_1 = true; ck_2_2 = true; ck_2_3 = true; ck_2_4 = true; ck_2_5 = true; ck_2_6 = true; ck_2_7 = true;
  ct_3 = 4; ck_3_1 = true; ck_3_2 = true; ck_3_3 = true; ck_3_4 = true;
  ct_4 = 3; ck_4_1 = true; ck_4_2 = true; ck_4_3 = true;
  ct_5 = 5; ck_5_1 = true; ck_5_2 = true; ck_5_3 = true; ck_5_4 = true; ck_5_5 = true;
  ct_6 = 5; ck_6_1 = true; ck_6_2 = true; ck_6_3 = true; ck_6_4 = true; ck_6_5 = true;
  ct_7 = 5; ck_7_1 = true; ck_7_2 = true; ck_7_3 = true; ck_7_4 = true; ck_7_5 = true;
  ct_8 = 5; ck_8_1 = true; ck_8_2 = true; ck_8_3 = true; ck_8_4 = true; ck_8_5 = true;

  ckArray_1_1: string[] = ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_1_2: string[] = ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_1_3: string[] = ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_1_4: string[] = ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_1_5: string[] = ['ck3D', 'ckAuftrag', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ckArray_2_1: string[] = ['ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_2_2: string[] = ['ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_2_3: string[] = ['ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_2_4: string[] = ['ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_2_5: string[] = ['ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_2_6: string[] = ['ckAuftrag', 'ckSonstiges'];
  ckArray_2_7: string[] = ['ckAuftrag', 'ckSonstiges'];

  ckArray_3_1: string[] = ['ck3D', 'ckAuftrag', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_3_2: string[] = ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_3_3: string[] = ['ck3D', 'ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_3_4: string[] = ['ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ckArray_4_1: string[] = ['ck3D', 'ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_4_2: string[] = ['ck3D', 'ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_4_3: string[] = ['ck3D', 'ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ckArray_5_1: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_5_2: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_5_3: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckSonstiges'];
  ckArray_5_4: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_5_5: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ckArray_6_1: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_6_2: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_6_3: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckSonstiges'];
  ckArray_6_4: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_6_5: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ckArray_7_1: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_7_2: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_7_3: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckSonstiges'];
  ckArray_7_4: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_7_5: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ckArray_8_1: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_8_2: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckSonstiges'];
  ckArray_8_3: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckSonstiges'];
  ckArray_8_4: string[] = ['ckAR', 'ckVR', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  ckArray_8_5: string[] = ['ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];

  ck3D = false;
  ckAuftrag = false;
  ckAR = false;
  ckVR = false;
  ckArchi = false;
  ckIngenie = false;
  ckProdukt = false;
  ckSonstiges = false;

  mainArray: string[] = ['ck3D', 'ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges'];
  firstArray = this.profile.get('subCat')['controls'];

  printValues() {
    this.isDisabled = true
    //console.log(this.profile.value);

  }

  //uncheck all the checkboxes
  resetCheckboxes() {

    for (var i = 0; i < 8; i++) {
      //var dynMainVar: string = this.mainArray[i];
      var dynVar1: string = 'ct_' + (i + 1);//child checkboxes count
      for (var j = 0; j < this[dynVar1]; j++) {
        var dynVar2: string = 'ckArray_' + (i + 1) + '_' + (j + 1);// ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges']


        var dynVar3: string = 'ck_' + (i + 1) + '_' + (j + 1);
        this[dynVar3] = true;
        this.firstArray[dynVar3].disable();


      }
    }

  }

  //apply select logic to the checkboxes
  applyCheckboxes() {
    //console.log("I am here!");

    for (var h = 0; h < 8; h++) {
      var dynMainVar: string = this.mainArray[h]; //['ck3D', 'ckAuftrag', 'ckAR', 'ckVR', 'ckArchi', 'ckIngenie', 'ckProdukt', 'ckSonstiges']

      for (var i = 0; i < 8; i++) {
        //var dynMainVar: string = this.mainArray[i];
        var dynVar1: string = 'ct_' + (i + 1);//child checkboxes count
        for (var j = 0; j < this[dynVar1]; j++) {
          var dynVar2: string = 'ckArray_' + (i + 1) + '_' + (j + 1);// ['ck3D', 'ckAuftrag', 'ckIngenie', 'ckProdukt', 'ckSonstiges']
          var pos: number = this[dynVar2].indexOf(dynMainVar);
          //console.log('ckArray_' + (i + 1) + '_' + (j + 1) + ' : ' + pos);
          if (pos >= 0 && this[dynMainVar] === true) {
            var dynVar3: string = 'ck_' + (i + 1) + '_' + (j + 1);
            this[dynVar3] = false;
            this.firstArray[dynVar3].enable();
            //console.log('ckArray_' + (i + 1) + '_' + (j + 1) + ' : ' + pos);
          }


        }
      }
    }


  }

  //checking which ckBox is selected
  checkBox(e) {
    var title: string = e.target.value;


    if (title === '3D-Druckfertigung') {
      this.ck3D = e.target.checked;

    } else if (title === 'Auftragsfertigung') {
      this.ckAuftrag = e.target.checked;


    } else if (title === 'Augmented Reality') {
      this.ckAR = e.target.checked;


    } else if (title === 'Virtual Reality') {
      this.ckVR = e.target.checked;

    } else if (title === 'Architekturleistungen') {
      this.ckArchi = e.target.checked;


    } else if (title === 'Ingenieurleistungen') {
      this.ckIngenie = e.target.checked;

    } else if (title === 'Produktentwicklung / Design') {
      this.ckProdukt = e.target.checked;


    } else if (title === 'Sonstiges') {
      this.ckSonstiges = e.target.checked;


    }

    this.resetCheckboxes();
    this.applyCheckboxes();

  }



  sendPostRequest(data: any): Observable<any> {
    return this.http.post<any>("https://1tcf4ywpjl.execute-api.eu-central-1.amazonaws.com/api/kontakt", data);
  }

  submitForm() {
    let obj: any = this.profile.value;
    let ref: string = this.referralPage;
    this.submitted = true;
    this.isDisabled = true;

    //inserting reference page name 
    if (isNull(ref) || ref.length <= 0) {
      obj.referralLink = 'DIREKT';
    } else {
      obj.referralLink = ref;
    }


    if (this.profile.invalid) {
      console.log('Invalid form');
      this.formResult = false;
      this.formLoad = true;
      return
    }

    this.sendPostRequest(obj).subscribe(
      res => {
        console.log(res);
        this.formLoad = true;
        if (isNull(res.err)) {
          this.formResult = true;
        } else {
          this.formResult = false;
        }
      }
    );
  }



  ngOnInit(): void {
    this.route.pathFromRoot
    this.route.url
      .subscribe(params => {
        let path: string = params[0].path;
        if (path == 'en') {
          this.translate.use('en');
          this.lang_captcha = 'en';
          this.cookie.setCookie('lang','en');
        } else {
          this.translate.use('de');
          this.lang_captcha = 'de';
          this.cookie.setCookie('lang','de');
        }
      });

/*     if (isPlatformBrowser(this.platformID)) {
      const defLang = this.cookie.getCookie();

      if (defLang == '##') {
        
      } else {
        this.lang_captcha = defLang;
      }
    } */
    this.referralPageText();
  }

  referralPageText() {
    this.route.paramMap
      .subscribe(params => {
        this.referralPage = params.get('ref');
        console.log(this.referralPage);
        //this.referralPage = 'Hare Krishna';
      });
  }

}

/*
captcha https://enngage.github.io/ngx-captcha/recaptcha2
*/
