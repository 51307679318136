<div class="page">
        <app-header></app-header>
    
      
        <!-- Widerruf -->
        <section class="section-xs bg-default object-wrap">
          <div class="section-md">
            <div class="container">
              <div class="row">
    
                <div class="col-md-12">
                  <h5>Widerrufrecht</h5>
                  <p>(1) Verbraucher haben bei Abschluss eines Fernabsatzgeschäfts grundsätzlich ein gesetzliches Widerrufsrecht, über das der Anbieter nach Maßgabe des gesetzlichen Musters nachfolgend informiert. Die Ausnahmen vom Widerrufsrecht sind in Absatz (2) geregelt. In Absatz (3) findet sich ein Muster-Widerrufsformular.</p>
                </div>
    
                <div class="col-md-12">
                    <p>&nbsp;</p>
                    <h5>Widerrufsbelehrung</h5>
                    <p>Widerrufsrecht</p>
                    <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angaben von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses bei Dienstleistungen und beim Kauf von Waren vierzehn Tage ab Erhalt der Ware.</p>
                    <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefonanruf oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</p>
                    <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
                    <p>&nbsp;</p>
                    <p>Folgen des Widerrufs bei Buchung einer Dienstleistung / einer Werkleistung</p>
                    <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
                    <p>Folgen des Widerrufs beim Kauf von Waren</p>
                    <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstige Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
                    <p>Wir können die Rückzahlung verweigern, bis wir die Ware wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.</p>
                    <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.</p>
                    <p>Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
                    <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
                    <p>&nbsp;</p>
                    <p>(2) Das Widerrufsrecht besteht nicht bei Verträgen zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind.</p>
                    <p>Das Widerrufsrecht besteht nicht bei Verträgen zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.</p>
                    <p>Das Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalten auch dann, wenn der Unternehmer mit der Ausführung des Vertrags begonnen hat, nachdem der Verbraucher ausdrücklich zugestimmt hat, dass der Unternehmer mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.</p>
                    <p>Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen auch dann, wenn der Unternehmer die Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Verbraucher dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch den Unternehmer verliert. Bei einem außerhalb von Geschäftsräumen geschlossenen Vertrag muss die Zustimmung des Verbrauchers auf einem dauerhaften Datenträger übermittelt werden.</p>
                    <p>(3) Über das Muster-Widerrufsformular informiert der Anbieter nach der gesetzlichen Regelung wie folgt:</p>
                </div>
    
                <div class="col-md-12">
                    <p>&nbsp;</p>
                    <h5>Muster-Widerrufsformular</h5>
                    <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
                    <p>&nbsp;</p>
                    <p>An:<br>3Dscout GmbH<br>Parkstr. 10<br>D-52372 Kreuzau</p>
                    <p>&nbsp;</p>
                    <p>Oder per Email: info@3dscout.de</p>
                </div>  
                
                <div class="col-md-12">
                    <p>&nbsp;</p>
                    <p>— Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag
                            <br>über den Kauf folgender Dienstleistungen / Waren (*)/
                            <br>— Bestellt am (*)/erhalten am (*)
                            <br>— Name des/der Verbraucher(s)
                            <br>— Anschrift des/der Verbraucher(s)
                            <br>— Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
                            <br>— Datum
                            </p>
                    <p>&nbsp;</p>
                    <p>(*) Unzutreffendes bitte streichen </p>
                </div>
    
                <div class="col-md-12">
                    <p>&nbsp;</p>
                    <p>Der Widerruf kann auch telefonisch erklärt werden:</p>
                    <p>Telefon: +49 2421 2997301</p>
                </div>
    
              </div>
            </div>
          </div>
    
        </section>
    
    
    
        <!-- Page Footer -->
        <app-footer></app-footer>
      </div>