import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookService } from 'src/app/services/cook.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menu3d:string; menuauftrag:string; menuar:string; menuvr:string; menuarchi:string; menuprod:string; menucontact:string; menucareer:string; logopath: string;
 

  constructor(@Inject(PLATFORM_ID) private platformID, public translate: TranslateService, public cookie: CookService) { }

  ngOnInit(): void {

    const langu = this.cookie.getCookie();
    if(langu == 'en'){
      this.logopath = "/en"
    }else{
      this.logopath = "/";
    }

    if (isPlatformBrowser(this.platformID)) {
      import('../../assets/js/script.min.js');
    }

    this.translate.get('path.3d').subscribe((s: string) => {
      this.menu3d = s;
    });

    this.translate.get('path.auftrag').subscribe((s: string) => {
      this.menuauftrag = s;
    });

    this.translate.get('path.ar').subscribe((s: string) => {
      this.menuar = s;
    });

    this.translate.get('path.vr').subscribe((s: string) => {
      this.menuvr = s;
    });

    this.translate.get('path.archi').subscribe((s: string) => {
      this.menuarchi = s;
    });

    this.translate.get('path.prod').subscribe((s: string) => {
      this.menuprod = s;
    });

    this.translate.get('path.contact').subscribe((s: string) => {
      this.menucontact = s;
    });

    this.translate.get('path.career').subscribe((s: string) => {
      this.menucareer = s;
    });

  }

  changeCountry(lang) {

    console.log('selected language is ' + lang);
    this.cookie.setCookie('lang', lang);
    this.translate.use(lang);
  }
}
