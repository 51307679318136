import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
//import '../../assets/js/script.min.js';

import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookService } from '../../services/cook.service';
@Component({
  selector: 'app-hauptseite',
  templateUrl: '../home.component.html',
  styleUrls: ['../home.component.css']
})
export class HauptseiteComponent implements OnInit {

  pagetitle: any;
  desc: string;
  constructor(@Inject(PLATFORM_ID) private platformID, public translate: TranslateService, public cookie: CookService, private route: ActivatedRoute, private meta: Meta, private title: Title) { }
  
  

  ngOnInit(): void {

    this.translate.use('de');
    this.cookie.setCookie('lang', 'de');
    console.log('DE site');

    if (isPlatformBrowser(this.platformID)) {


      this.translate.get('home.SEO.title').subscribe((res: any) => {
        this.pagetitle = res;
        this.title.setTitle(this.pagetitle);
      });

      this.translate.get('home.SEO.desc').subscribe((res: any) => {
        this.desc = res;
        this.meta.updateTag({ name: 'description', content: this.desc });
      });
    }
  }

}
