<header class="page-header">


    <!-- RD Navbar-->
    <div class="rd-navbar-wrap">
      <nav class="rd-navbar rd-navbar_corporate" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
        data-sm-device-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed"
        data-md-device-layout="rd-navbar-fixed" data-lg-device-layout="rd-navbar-fixed"
        data-xl-device-layout="rd-navbar-static" data-xxl-device-layout="rd-navbar-static"
        data-lg-layout="rd-navbar-static" data-xl-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static"
        data-stick-up-clone="false" data-sm-stick-up="true" data-md-stick-up="true" data-lg-stick-up="true"
        data-xl-stick-up="true" data-xxl-stick-up="true" data-md-stick-up-offset="60px"
        data-lg-stick-up-offset="145px" data-xl-stick-up-offset="145px" data-xxl-stick-up-offset="145px"
        data-body-class="rd-navbar-corporate-linked">

        <!-- RD Navbar Panel-->
        <div class="rd-navbar-panel">
          <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
          <!-- RD Navbar Brand-->
          <div class="rd-navbar-brand rd-navbar-static--hidden"><a itemscope itemtype="http://schema.org/LocalBusiness" class="brand-name" href="{{logopath}}"><img
            itemprop="image" src="assets/images/logo-3dscout-gmbh.png" alt="{{ 'home.SEO.alt.logo' | translate }}" srcset="assets/images/logo-3dscout-gmbh.png 2x" /></a>
          </div>
        </div>
        <!-- RD Navbar Top Panel-->

        <div class="rd-navbar-top-panel rd-navbar-top-panel-dark rd-navbar-top-panel_extended">
          <div class="rd-navbar-top-panel__main">
            <div class="rd-navbar-top-panel__content">
              <div class="rd-navbar-top-panel__content-top">
                <div class="rd-navbar-top-panel__left">
                </div>
                <div class="rd-navbar-top-panel__right">
                  <ul class="rd-navbar-items-list">
                    <li class="displayNone">
                      <ul class="list-inline-xxs">
                        <li><a href="#" data-toggle="modal" data-target="#modalLogin">{{ 'header.signIn' | translate }}</a></li>
                        <li><a href="#" data-toggle="modal" data-target="#modalRegister">{{ 'header.createAccount' | translate }}</a></li>
                      </ul>
                    </li>
                    <li>
                      <ul class="list-inline-xxs">
                          <li><a class="icon icon-xxs icon-primary fa fa-xing" href="https://www.xing.com/companies/3dscoutgmbh" target="_blank"></a></li>
                          <li><a class="icon icon-xxs icon-primary fa fa-linkedin" href="https://www.linkedin.com/company/3dscout-gmbh" target="_blank"></a></li>
                          <li><a class="icon icon-xxs icon-primary fa fa-facebook" href="https://www.facebook.com/3Dscout-GmbH-107713757515808/" target="_blank"></a></li>
                      </ul>
                    </li>
                    <li>
                      <ul class="list-inline-xxs">
                        <li><a class="flag-icon flag-icon-gb flag-icon-squared" href="/en" ></a></li>
                        <li><a class="flag-icon flag-icon-de flag-icon-squared" href="/"></a></li>
                      </ul>
                     
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rd-navbar-top-panel rd-navbar-top-panel_extended">
          <div class="rd-navbar-top-panel__main">
            <div class="rd-navbar-top-panel__toggle rd-navbar-fixed__element-1 rd-navbar-static--hidden"
              data-rd-navbar-toggle=".rd-navbar-top-panel__main"><span></span></div>
            <div class="rd-navbar-top-panel__content">
              <div class="rd-navbar-top-panel__content-top" class="displayNone">
                <div class="rd-navbar-top-panel__left">
                </div>
                <div class="rd-navbar-top-panel__right">
                  <ul class="rd-navbar-items-list">
                    <li>
                      <ul class="list-inline-xxs">
                        <li><a href="#" data-toggle="modal" data-target="#modalLogin">{{ 'header.signIn' | translate }}</a></li>
                        <li><a href="#" data-toggle="modal" data-target="#modalRegister">{{ 'header.createAccount' | translate }}</a></li>
                      </ul>
                    </li>
                    <li>
                      <ul class="list-inline-xxs">
                          <li><a class="icon icon-xxs icon-primary fa fa-xing" href="https://www.xing.com/companies/3dscoutgmbh" target="_blank"></a></li>
                          <li><a class="icon icon-xxs icon-primary fa fa-linkedin" href="https://www.linkedin.com/company/3dscout-gmbh" target="_blank"></a></li>
                          <li><a class="icon icon-xxs icon-primary fa fa-facebook" href="https://www.facebook.com/3Dscout-GmbH-107713757515808/" target="_blank"></a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rd-navbar-top-panel__content-bottom rd-navbar-content_middle rd-navbar-content_justify">
                <!-- RD Navbar Branding-->
                <div class="rd-navbar-brand rd-navbar-fixed--hidden">
                  <a itemscope itemtype="http://schema.org/LocalBusiness" class="brand-name" href="{{ logopath }}"><img itemprop="image" src="assets/images/logo-3dscout-gmbh.png" alt="{{ 'home.SEO.alt.logo' | translate }}"
                      srcset="assets/images/logo-3dscout-gmbh.png 2x" /></a></div>
                <!-- End RD Navbar Branding-->
                <ul class="list-bordered">
                  <li>
                    <div class="unit flex-row align-items-center unit-spacing-sm">
                      <div class="unit-left"><span class="icon icon-md icon-primary icon-svg-1"></span>
                      </div>
                      <div class="unit-body">
                        <dl class="list-terms-modern">
                          <dd><a href="{{ menu3d }}/">{{ 'header.menu.3d' | translate }}</a> <span>&nbsp;{{ 'header.menu.and' | translate }}&nbsp;</span> <a href="{{ menuauftrag }}/">{{ 'header.menu.auftrag' | translate }}</a></dd>
                        </dl>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="unit flex-row align-items-center unit-spacing-sm">
                      <div class="unit-left"><span class="icon icon-md icon-primary icon-svg-2"></span>
                      </div>
                      <div class="unit-body">
                        <dl class="list-terms-modern">
                            <dd><a href="{{ menuar }}/">{{ 'header.menu.ar' | translate }}</a> <span>&nbsp;{{ 'header.menu.and' | translate }}&nbsp;</span> <a href="{{ menuvr }}/">{{ 'header.menu.vr' | translate }}</a></dd>
                        </dl>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="unit flex-row align-items-center unit-spacing-sm">
                      <div class="unit-left"><span class="icon icon-md icon-primary icon-svg-3"></span>
                      </div>
                      <div class="unit-body">
                        <dl class="list-terms-modern">
                            <dd><a href="{{ menuarchi }}/">{{ 'header.menu.archi' | translate }} </a> <span>&nbsp;{{ 'header.menu.and' | translate }}&nbsp;</span> <a href="{{ menuprod }}/">{{ 'header.menu.prod' | translate }}</a></dd>
                        </dl>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="rd-navbar-bottom-panel rd-navbar-search-wrap">
          <!-- RD Navbar Nav-->
          <div class="rd-navbar-nav-wrap rd-navbar-search_not-collapsable">

            <!-- RD Search-->

            <div class="rd-navbar-search_collapsable">
              <ul class="rd-navbar-nav">

                <li><a href="#">{{ 'header.menu.home' | translate }}</a></li>
                <li><a href="#">{{ 'header.menu.portfolio' | translate }}</a>
                  <ul class="rd-navbar-dropdown">
                    <li><a href="{{ menu3d }}/">{{ 'header.menu.3d' | translate }}</a><a class="mobund"> {{ 'header.menu.and' | translate }} </a><a href="{{ menuauftrag }}/">{{ 'header.menu.auftrag' | translate }}</a></li>
                    <li><a href="{{ menuar }}/">{{ 'header.menu.ar' | translate }} </a><a href="#" class="mobund"> {{ 'header.menu.and' | translate }} </a><a href="{{ menuvr }}/">{{ 'header.menu.vr' | translate }}</a></li>
                    <li><a href="{{ menuarchi }}/">{{ 'header.menu.archi' | translate }} </a><a href="#" class="mobund"> {{ 'header.menu.and' | translate }} </a><a href="{{ menuprod }}/">{{ 'header.menu.prod' | translate }}</a></li>
                  </ul>
                </li>
                <li><a href="{{ menucontact }}/">{{ 'header.menu.contact' | translate }}</a></li>               
                <li><a href="{{ menucareer }}/">{{ 'header.menu.career' | translate }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>

  