<div class="page">
    <app-header></app-header>


    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
        <div class="section-md">
            <div class="container">
                <div class="row">

                    <div class="col-md-12">
                        <h5>Information according to § 5 TMG</h5>
                        <p>3Dscout GmbH<br>Parkstr. 10<br>D-52372 Kreuzau</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Contact:</h5>
                        <p>Telephone: +49 2421 2997301<br>Email: info@3dscout.de</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Managing Director:</h5>
                        <p>Dipl. Kauffrau (FH) Rushani Gnanes</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Commercial register entry: </h5>
                        <p>Registered at Dueren Local Court under HRB 8199</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Sales tax identification number according to §27 a sales tax law:</h5>
                        <p>DE327217142</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Responsible for the content according to § 55 Abs. 2 RStV:</h5>
                        <p>Rushani Gnanes, address as above</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Extrajudicial dispute resolution</h5>
                        <p>The EU platform for out-of-court online dispute resolution can be found here: <a
                                href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
                        <p>The operator is neither willing nor obliged to participate in any dispute resolution procedure before a consumer redress body. </p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Liability for contents</h5>
                        <p>The site operator assumes no liability for information transmitted or stored by third parties. There is no obligation on the part of the site operator to monitor this information. If illegal information is transmitted or stored by third parties, the site operator is liable only if he does not comply with a legitimate request to delete the information. Obviously illegal information will be deleted as soon as it becomes known.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Liability for links</h5>
                        <p>The site operator has no influence on external links. Before the links on these pages are published, they have been checked to a reasonable extent for compliance with the statutory provisions. The site operator is not responsible for changes that occur on the pages that can be accessed via external links. In particular, a continuous check of external links for legal violations is not reasonable. If the site operator is notified of illegal content that can be accessed via external links, he will remove these links immediately.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Copyright</h5>
                        <p>The copyright to all texts and multimedia content available on the operator's pages lies with the operator of the site, unless deviating copyrights are separately stated. If the copyrights of third parties are not indicated, a notification via e-mail is requested. The site operator will remove such contents immediately. The processing, distribution and reproduction of content is not permitted without the express consent of the site operator.</p>
                    </div>

                </div>
            </div>
        </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
</div>