<div class="page">
  <app-header></app-header>
  <!-- Swiper-->
  <section>
    <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
      data-loop="false" data-autoplay="5000">
      <div class="swiper-wrapper">
        <div class="swiper-slide" data-slide-bg="assets/images/kontakt/Header.jpg">

        </div>

      </div>

    </div>
  </section>

  <!-- Orange line under slider -->
  <section class="section-xs bg-accent"></section>
  <!-- Impressive Features-->


  <section class="section-md bg-default">
    <div class="container">
      <div class="row row-50">

        <div class="col-md-7 col-lg-12">

          <h4 class="heading-decorated">{{ 'kontakt.title' | translate }}</h4>
          <form [formGroup]="profile" class="rd-mailform rd-mailform_style-1" data-form-output="form-output-global"
            data-form-type="contact" method="post" action="bat/rd-mailform.php">


            <div class="form-wrap">
              <select class="form-input" formControlName="sex">
                <option>{{ 'kontakt.form.male' | translate }}</option>
                <option>{{ 'kontakt.form.female' | translate }}</option>
              </select>

            </div>


            <div class="form-wrap"
              [ngClass]="{'feedbackErr': submitted && f.fname.errors}">
              <input class="form-input" id="contact-vorname" type="text" formControlName="fname" name="fname">
              <label class="form-label" for="contact-vorname">{{ 'kontakt.form.fname' | translate }}</label>

            </div>

            <div class="form-wrap"
              [ngClass]="{'feedbackErr': submitted && f.lname.errors}">
              <input class="form-input" id="contact-nachname" type="text" formControlName="lname" name="lname">
              <label class="form-label" for="contact-nachname">{{ 'kontakt.form.lname' | translate }}</label>
            </div>

            <div class="form-wrap"
              [ngClass]="{'feedbackErr': submitted && f.email.errors}">
              <input class="form-input" id="contact-email" type="email" formControlName="email" name="email"
                data-constraints="@Email @Required">
              <label class="form-label" for="contact-email">{{ 'kontakt.form.email' | translate }}</label>
            </div>

            <div class="form-wrap">
              <!-- <input class="form-input" id="contact-phone" type="phone" name="phone" data-constraints="@tel"> -->
              <input class="form-input" id="contact-phone" type="phone" formControlName="phone" name="phone">
              <label class="form-label" for="contact-phone">{{ 'kontakt.form.phone' | translate }}</label>
            </div>

            <div class="form-wrap">
              <input class="form-input" id="contact-firma" type="text" formControlName="firma" name="firma">
              <label class="form-label" for="contact-firma">{{ 'kontakt.form.company' | translate }}</label>
            </div>

            <div class="form-wrap">
              <input class="form-input" type="text" name="" disabled>
              <label class="form-label">
                <h6>{{ 'kontakt.subtitle' | translate }}</h6>
              </label>

            </div>

            <div class="form-wrap" formGroupName="mainCat">
              <h6>{{ 'kontakt.optMain.title' | translate }}</h6>
              <div><input type="checkbox" value="3D-Druckfertigung" (change)="checkBox($event)" name="3d"
                  formControlName="threed"> {{ 'kontakt.optMain.3d' | translate }}</div>
              <div><input type="checkbox" value="Auftragsfertigung" (change)="checkBox($event)" name="auftrag"
                  formControlName="auftrag"> {{ 'kontakt.optMain.auftrag' | translate }}</div>
              <div><input type="checkbox" value="Augmented Reality" (change)="checkBox($event)" name="ar"
                  formControlName="ar"> {{ 'kontakt.optMain.ar' | translate }}</div>
              <div><input type="checkbox" value="Virtual Reality" (change)="checkBox($event)" name="vr"
                  formControlName="vr"> {{ 'kontakt.optMain.vr' | translate }}</div>
              <div><input type="checkbox" value="Architekturleistungen" (change)="checkBox($event)" name="archi"
                  formControlName="archi"> {{ 'kontakt.optMain.archi' | translate }}</div>
              <div><input type="checkbox" value="Ingenieurleistungen" (change)="checkBox($event)" name="ingeni"
                  formControlName="ingeni"> {{ 'kontakt.optMain.ingeni' | translate }}</div>
              <div><input type="checkbox" value="Produktentwicklung / Design" (change)="checkBox($event)" name="prod"
                  formControlName="prod"> {{ 'kontakt.optMain.prod' | translate }}</div>
              <div><input type="checkbox" value="Sonstiges" (change)="checkBox($event)" name="others"
                  formControlName="others"> {{ 'kontakt.optMain.others' | translate }}</div>
            </div>

            <div class="form-wrap" formGroupName="subCat">
              <h6>{{ 'kontakt.optSub.title' | translate }}</h6>
              <div><input type="checkbox"  value="3D-Druck im SLS-Verfahren" id="3d-druck_im_sls-verfahren"
                  name="3d-druck_im_sls-verfahren" formControlName="ck_1_1">
                {{ 'kontakt.optSub.group1.item1' | translate }}</div>
              <div><input type="checkbox"  value="3d-druck im sla-verfahren" id="3d-druck_im_sla-verfahren"
                  name="3d-druck_im_sla-verfahren" formControlName="ck_1_2">
                {{ 'kontakt.optSub.group1.item2' | translate }}</div>
              <div><input type="checkbox"  value="3d-druck im slm-verfahren" id="3d-druck_im_slm-verfahren"
                  name="3d-druck_im_slm-verfahren" formControlName="ck_1_3">
                {{ 'kontakt.optSub.group1.item3' | translate }}</div>
              <div><input type="checkbox"  value="3d-druck im polyjet-verfahren" id="3d-druck_im_polyjet-verfahren"
                  name="3d-druck_im_polyjet-verfahren" formControlName="ck_1_4">
                {{ 'kontakt.optSub.group1.item4' | translate }}</div>
              <div><input type="checkbox"  value="3d-drucken allgemein" id="3d-drucken_allgemein"
                  name="3d-drucken_allgemein" formControlName="ck_1_5"> {{ 'kontakt.optSub.group1.item5' | translate }}
              </div>

              <div><input type="checkbox"  value="vakuumgießen für kunststoffe" id="vakuumgießen_für_kunststoffe"
                  name="vakuumgießen_für_kunststoffe" formControlName="ck_2_1">
                {{ 'kontakt.optSub.group2.item1' | translate }}</div>
              <div><input type="checkbox"  value="druckgussverfahren für aluminium"
                  id="druckgussverfahren_für_aluminium" name="druckgussverfahren_für_aluminium"
                  formControlName="ck_2_2"> {{ 'kontakt.optSub.group2.item2' | translate }}</div>
              <div><input type="checkbox"  value="rapid-protoyping" id="rapid-protoyping" name="rapid-protoyping"
                  formControlName="ck_2_3"> {{ 'kontakt.optSub.group2.item3' | translate }}</div>
              <div><input type="checkbox"  value="werkzeugbau" id="werkzeugbau" name="werkzeugbau"
                  formControlName="ck_2_4"> {{ 'kontakt.optSub.group2.item4' | translate }}</div>
              <div><input type="checkbox"  value="kleinserienfertigung bis 1000 teile"
                  id="kleinserienfertigung_bis_1000_teile" name="kleinserienfertigung_bis_1000_teile"
                  formControlName="ck_2_5">
                {{ 'kontakt.optSub.group2.item5' | translate }}</div>
              <div><input type="checkbox"  value="mittlere serienfertigung bis 10000 teile"
                  id="mittlere_serienfertigung_bis_10000_teile" name="mittlere_serienfertigung_bis_10000_teile"
                  formControlName="ck_2_6">
                {{ 'kontakt.optSub.group2.item6' | translate }}</div>
              <div><input type="checkbox"  value="großserienfertigung ab 10000 teile"
                  id="großserienfertigung_ab_10000_teile" name="großserienfertigung_ab_10000_teile"
                  formControlName="ck_2_7"> {{ 'kontakt.optSub.group2.item7' | translate }}</div>


              <div><input type="checkbox"  value="cad-dienstleistungen" id="cad-dienstleistungen"
                  name="cad-dienstleistungen" formControlName="ck_3_1"> {{ 'kontakt.optSub.group3.item1' | translate }}
              </div>
              <div><input type="checkbox"  value="cae-dienstleistungen" id="cae-dienstleistungen"
                  name="cae-dienstleistungen" formControlName="ck_3_2"> {{ 'kontakt.optSub.group3.item2' | translate }}
              </div>
              <div><input type="checkbox"  value="reverse-engineering " id=" reverse-engineering_"
                  name="reverse-engineering_" formControlName="ck_3_3"> {{ 'kontakt.optSub.group3.item3' | translate }}
              </div>
              <div><input type="checkbox"  value="produktvisualisierung" id="produktvisualisierung"
                  name="produktvisualisierung" formControlName="ck_3_4"> {{ 'kontakt.optSub.group3.item4' | translate }}
              </div>



              <div><input type="checkbox"  value="3d-datenerstellung aus 2d-daten" id="3d-datenerstellung_aus_2d-daten"
                  name="3d-datenerstellung_aus_2d-daten" formControlName="ck_4_1">
                {{ 'kontakt.optSub.group4.item1' | translate }}</div>
              <div><input type="checkbox"  value="3d-datenerstellung aus bauteilscan"
                  id="3d-datenerstellung_aus_bauteilscan" name="3d-datenerstellung_aus_bauteilscan"
                  formControlName="ck_4_2"> {{ 'kontakt.optSub.group4.item2' | translate }}</div>
              <div><input type="checkbox"  value="3d-datenerstellung aus skizze" id="3d-datenerstellung_aus_skizze"
                  name="3d-datenerstellung_aus_skizze" formControlName="ck_4_3">
                {{ 'kontakt.optSub.group4.item3' | translate }}</div>

              <div><input type="checkbox"  value="3d-rendering für exterior" id="3d-rendering_für_exterior"
                  name="3d-rendering_für_exterior" formControlName="ck_5_1">
                {{ 'kontakt.optSub.group5.item1' | translate }}</div>
              <div><input type="checkbox"  value="3d-rendering für interior" id="3d-rendering_für_interior"
                  name="3d-rendering_für_interior" formControlName="ck_5_2">
                {{ 'kontakt.optSub.group5.item2' | translate }}</div>
              <div><input type="checkbox"  value="3d-rendering für maschinen " id=" 3d-rendering_für_maschinen_"
                  name="3d-rendering_für_maschinen_" formControlName="ck_5_3">
                {{ 'kontakt.optSub.group5.item3' | translate }}</div>
              <div><input type="checkbox"  value="3d-rendering für produkte" id="3d-rendering_für_produkte"
                  name="3d-rendering_für_produkte" formControlName="ck_5_4">
                {{ 'kontakt.optSub.group5.item4' | translate }}</div>
              <div><input type="checkbox"  value="3d-rendering für sonstiges " id=" 3d-rendering_für_sonstiges_"
                  name="3d-rendering_für_sonstiges_" formControlName="ck_5_5">
                {{ 'kontakt.optSub.group5.item5' | translate }}</div>

              <div><input type="checkbox"  value="3d-animation für exterior" id="3d-animation_für_exterior"
                  name="3d-animation_für_exterior" formControlName="ck_6_1">
                {{ 'kontakt.optSub.group6.item1' | translate }}</div>
              <div><input type="checkbox"  value="3d-animation für interior" id="3d-animation_für_interior"
                  name="3d-animation_für_interior" formControlName="ck_6_2">
                {{ 'kontakt.optSub.group6.item2' | translate }}</div>
              <div><input type="checkbox"  value="3d-animation für maschinen" id="3d-animation_für_maschinen"
                  name="3d-animation_für_maschinen" formControlName="ck_6_3">
                {{ 'kontakt.optSub.group6.item3' | translate }}</div>
              <div><input type="checkbox"  value="3d-animation für produkte" id="3d-animation_für_produkte"
                  name="3d-animation_für_produkte" formControlName="ck_6_4">
                {{ 'kontakt.optSub.group6.item4' | translate }}</div>
              <div><input type="checkbox"  value="3d-animation für sonstiges " id=" 3d-animation_für_sonstiges_"
                  name="3d-animation_für_sonstiges_" formControlName="ck_6_5">
                {{ 'kontakt.optSub.group6.item5' | translate }}</div>

              <div><input type="checkbox"  value="virtual reality für exterior" id="virtual_reality_für_exterior"
                  name="virtual_reality_für_exterior" formControlName="ck_7_1">
                {{ 'kontakt.optSub.group7.item1' | translate }}</div>
              <div><input type="checkbox"  value="virtual reality für interior " id=" virtual_reality_für_interior_"
                  name="virtual_reality_für_interior_" formControlName="ck_7_2">
                {{ 'kontakt.optSub.group7.item2' | translate }}</div>
              <div><input type="checkbox"  value="virtual reality für maschine" id="virtual_reality_für_maschine"
                  name="virtual_reality_für_maschine" formControlName="ck_7_3">
                {{ 'kontakt.optSub.group7.item3' | translate }}</div>
              <div><input type="checkbox"  value="virtual reality für produkte" id="virtual_reality_für_produkte"
                  name="virtual_reality_für_produkte" formControlName="ck_7_4">
                {{ 'kontakt.optSub.group7.item4' | translate }}</div>
              <div><input type="checkbox"  value="virtual reality für sonstiges " id=" virtual_reality_für_sonstiges_"
                  name="virtual_reality_für_sonstiges_" formControlName="ck_7_5">
                {{ 'kontakt.optSub.group7.item5' | translate }}</div>

              <div><input type="checkbox"  value="augmented reality für exterior" id="augmented_reality_für_exterior"
                  name="augmented_reality_für_exterior" formControlName="ck_8_1">
                {{ 'kontakt.optSub.group8.item1' | translate }}</div>
              <div><input type="checkbox"  value="augmented reality für interior" id="augmented_reality_für_interior"
                  name="augmented_reality_für_interior" formControlName="ck_8_2">
                {{ 'kontakt.optSub.group8.item2' | translate }}</div>
              <div><input type="checkbox"  value="augmented reality für maschine" id="augmented_reality_für_maschine"
                  name="augmented_reality_für_maschine" formControlName="ck_8_3">
                {{ 'kontakt.optSub.group8.item3' | translate }}</div>
              <div><input type="checkbox"  value="augmented reality für produkte" id="augmented_reality_für_produkte"
                  name="augmented_reality_für_produkte" formControlName="ck_8_4">
                {{ 'kontakt.optSub.group8.item4' | translate }}</div>
              <div><input type="checkbox"  value="augmented reality für sonstiges " id="
                  augmented_reality_für_sonstiges_" name="augmented_reality_für_sonstiges_" formControlName="ck_8_5">
                {{ 'kontakt.optSub.group8.item5' | translate }}</div>



            </div>

            <div class="form-wrap form-wrap_icon linear-icon-feather">
              <textarea class="form-input" id="contact-message" name="message" formControlName="message"
                data-constraints="@Required"></textarea>
              <!-- <label class="form-label" for="contact-message">{{ 'kontakt.optSub.message' | translate }}</label> -->
            </div>

            <div class="form-wrap">
              <!--
                  <div class="form-group">
                      <input class="form-input" type="file"  formControlName="fileUpl">
                  </div>
                   -->
              <div class="form-group input-group-lg">
                <!-- <input class="form-input" id="contact-firma" type="text" name="datei" placeholder="Dateititel" formControlName="dateiname"> -->
              </div>


            </div>

            
            <div *ngIf="windowDefined">
              <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false" [size]="size"
                [hl]="lang_captcha" [theme]="theme" [type]="type" formControlName="recaptcha" name="recaptcha">
              </ngx-recaptcha2>
            </div>

            <div class="form-wrap">
              <div><input type="checkbox" class="redcolor"  value="datenschutz" id="cb-datenschutz"
                name="cb-datenschutz" formControlName="datenschutz"> <a href="{{ 'path.datenschutz' | translate }}/">&nbsp;{{ 'kontakt.datenschutz.link' | translate }}</a>  {{ 'kontakt.datenschutz.text' | translate }}
            </div>
            </div>


            <div class="form-group" id="resultDiv" *ngIf="formLoad">
              <br>
              <h6 class="greencolor" *ngIf="formResult" id="success">{{ 'kontakt.form.result.success' | translate }}
              </h6>
              <h6 class="redcolor" *ngIf="!formResult" id="fail">{{ 'kontakt.form.result.failure' | translate }}</h6>
            </div>



            <button (click)="submitForm()" class="button button-primary"
              type="submit">{{ 'kontakt.optSub.send' | translate }}</button>

          </form>
          <!-- {{profile.status}}
          <button [disabled]="!profile.valid" (click) = "printValues()">Print</button>
           -->

        </div>

      </div>
    </div>
  </section>


  <!-- Page Footer -->
  <app-footer></app-footer>
</div>