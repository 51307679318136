import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  menuimpressum:string; menuagb:string; menuwiderruf:string; menudatenschutz:string;

  constructor(public translate:TranslateService) { }

  ngOnInit(): void {

    this.translate.get('path.impressum').subscribe((s: string) => {
      this.menuimpressum = s;
    });

    this.translate.get('path.agb').subscribe((s: string) => {
      this.menuagb = s;
    });

    this.translate.get('path.widerruf').subscribe((s: string) => {
      this.menuwiderruf = s;
    });

    this.translate.get('path.datenschutz').subscribe((s: string) => {
      this.menudatenschutz = s;
    });
  }

}
