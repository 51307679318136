import { NgModule, Inject } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import * as translationEn from '../../assets/i18n/en.json';
import * as translationDe from '../../assets/i18n/de.json';
import { Observable, of } from 'rxjs';
import { CookService } from '../services/cook.service';

const TRANSLATIONS = {
  en: translationEn,
  de: translationDe,
};

export class JSONModuleLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang]);
  }
}

export function translateLoaderFactory(http: HttpClient, platform: any) {
  if (isPlatformBrowser(platform)) {
    return new TranslateHttpLoader(http);
  } else {
     return new JSONModuleLoader();
  }
}


@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, PLATFORM_ID]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(@Inject(PLATFORM_ID) private platformID, translate: TranslateService, public cookie: CookService) {
    
    

/*     translate.addLangs(['en', 'de']);
    translate.setDefaultLang('de');
    //translate.use('de');

    if (isPlatformBrowser(this.platformID)) {
      const defLang = this.cookie.getCookie();

      if (defLang == '##') {
        const browserLang = translate.getBrowserLang();
        this.cookie.setCookie('lang', browserLang.match(/en|de/) ? browserLang : 'de');
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
  
      } else {
        translate.use(defLang.match(/en|de/) ? defLang : 'de');
      }

    } */

    /* const defLang = this.cookie.getCookie();
    

     */


  }
}

