import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { CookService } from 'src/app/services/cook.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformID, public translate: TranslateService, public cookie: CookService, router: Router) {

  }
  ngOnInit(): void {

    
    /* if (isPlatformBrowser(this.platformID)) {
      this.translate.addLangs(['en', 'de']);
      const defLang = this.cookie.getCookie();
      this.translate.setDefaultLang('de');
      //this.translate.use('de');

      if (defLang == '##') {
        const browserLang = this.translate.getBrowserLang();
        this.cookie.setCookie('lang', browserLang.match(/en|de/) ? browserLang : 'de');
        this.translate.use(browserLang.match(/en|de/) ? browserLang : 'de');

      } else {
        this.translate.use(defLang.match(/en|de/) ? defLang : 'de');
      }

      if (!this.cookie.checkCookie('covid19')) {
        this.cookie.setCookie('covid19', '1');
        //router.navigate(['covid19']);
      }

    } */


  }


}
