<div class="page">
    <app-header></app-header>


    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
        <div class="section-md">
            <div class="container">
                <div class="row">

                    <div class="col-md-12">
                        <h5>Angaben gemäß § 5 TMG:</h5>
                        <p>3Dscout GmbH<br>Parkstr. 10<br>D-52372 Kreuzau</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Kontakt:</h5>
                        <p>Telefon: +49 2421 2997301<br>Email: info@3dscout.de</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Geschäftsführerin:</h5>
                        <p>Dipl. Kauffrau (FH) Rushani Gnanes</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Handelsregistereintrag: </h5>
                        <p>Eingetragen beim Amtsgericht Düren unter der HRB 8199</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</h5>
                        <p>DE327217142</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h5>
                        <p>Rushani Gnanes, Anschrift wie oben</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Außergerichtliche Streitbeilegung</h5>
                        <p>Die Plattform der EU zur außergerichtlichen Online-Streitbeilegung finden Sie hier: <a
                                href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
                        <p>Der Betreiber ist weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor
                            einer Verbraucherschlichtungsstelle teilzunehmen. </p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Haftung für Inhalte</h5>
                        <p>Der Seitenbetreiber übernimmt keine Haftung für von Dritten übermittelte oder gespeicherte
                            Informationen. Eine Pflicht zur Überwachung dieser Informationen besteht seitens des
                            Seitenbetreibers nicht. Sofern rechtswidrige Informationen von Dritten übermittelt oder
                            gespeichert werden, haftet der Seitenbetreiber erst, wenn er einer berechtigten Aufforderung
                            zur Löschung der Informationen nicht nachkommt. Offensichtlich rechtswidrige Informationen
                            werden umgehend nach Bekanntwerden gelöscht.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Haftung für Links</h5>
                        <p>Der Seitenbetreiber hat keinen Einfluss auf externe Links. Vor Veröffentlichung der Links auf
                            diesen Seiten wurden diese im zumutbaren Rahmen auf Übereinstimmung mit den gesetzlichen
                            Bestimmungen geprüft. Der Seitenbetreiber ist nicht verantwortlich für Änderungen, die sich
                            auf den über externen Links aufrufbaren Seiten ergeben. Insbesondere ist eine durchgehende
                            Kontrolle von externen Links auf Rechtsverletzungen nicht zumutbar. Sofern der
                            Seitenbetreiber auf rechtswidrige Inhalte hingewiesen wird, die über externe Links abrufbar
                            sind, entfernt er diese Links umgehend.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Urheberrecht</h5>
                        <p>Das Urheberrecht an allen auf den Seiten des Betreibers abrufbaren Texten und multimedialen
                            Inhalten liegt beim Seitenbetreiber, soweit abweichende Urheberrechte nicht gesondert
                            ausgewiesen werden. Sofern die Urheberrechte Dritter nicht ausgewiesen sind, wird um eine
                            Mitteilung via E-Mail gebeten. Der Seitenbetreiber wird solche Inhalte umgehend entfernen.
                            Die Verarbeitung, Verbreitung und Vervielfältigung von Inhalten ist ohne die ausdrückliche
                            Zustimmung des Seitenbetreibers nicht zulässig.</p>
                    </div>

                </div>
            </div>
        </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
</div>