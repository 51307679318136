<div class="page">
    <app-header></app-header>
    <!-- Swiper-->
    <section>
        <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
        data-loop="false" data-autoplay="5000">
        <div class="swiper-wrapper">
            <div class="swiper-slide" data-slide-bg="assets/images/page-ar/Header.jpg">
            <div class="swiper-slide-caption text-left">
              <div class="container">
                <div class="row justify-content-start justify-content-xxl-end">
                  <div class="col-lg-10 col-xxl-6">
                    <h1 data-caption-animate="fadeInUpSmall">{{ 'ar.slider.title' | translate }}</h1>
                    <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">
                      {{ 'ar.slider.subtitle' | translate }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
  
      </div>

    </section>

    <!-- Orange line under slider -->
    <section class="section-xs bg-accent"></section>
    <!-- Impressive Features-->


    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
      <div class="section-md">
        <div class="container">
          <div class="row">

            <div class="col-md-6">
              <h4 class="heading-decorated">{{ 'ar.block1.title' | translate }}</h4>
              <p>{{ 'ar.block1.paraLeft.text1' | translate }}<a
                class="orange" href="Produktentwicklung">{{ 'ar.block1.paraLeft.orange1' | translate }}</a>{{ 'ar.block1.paraLeft.text2' | translate }}<a
                class="orange" href="3d-druck">{{ 'ar.block1.paraLeft.orange2' | translate }}</a>{{ 'ar.block1.paraLeft.text3' | translate }}<a
                class="orange" href="Architekturleistungen">{{ 'ar.block1.paraLeft.orange3' | translate }}</a>{{ 'ar.block1.paraLeft.text4' | translate }}</p>
            </div>

            <div class="col-md-6">
              <h4 class="heading-decorated-blank">&nbsp;</h4>
              <p>{{ 'ar.block1.paraRight.text1' | translate }}<a
                href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block1.paraRight.anchor' | translate }}</a></p>
            </div>
          </div>
        </div>
      </div>

    </section>

    <!-- IHRE VORTEILE -->
    <section class="bg-default object-wrap">
      <div class="">
        <div class="container">
          <div class="row">
              <div class="col-lg-6">
                  <h4 class="heading-decorated">{{ 'ar.block2.title' | translate }}</h4>
                  <ul class="c">
                    <li><p>&#9673;&nbsp;&nbsp;{{ 'ar.block2.paraLeft.text1' | translate }}</p></li>
                    <li><p>&#9673;&nbsp;&nbsp;{{ 'ar.block2.paraLeft.text2' | translate }}</p></li>
                    <li><p>&#9673;&nbsp;&nbsp;{{ 'ar.block2.paraLeft.text3' | translate }}</p></li>
                  </ul>
                </div>
            <div class="col-lg-6">

              <div class="col-md-12">
                <!-- Post project-->
                <article class="post-project">

                  <figure>
                    <video controls autoplay="autoplay" loop  width="530" height="350">
                      <source src="assets/images/page-ar/augmented-reality-3dscout.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>

                  </figure>


                </article>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>


         <!--image block-->
         <section class="section-xs bg-default text-center">
          <div class="container">
            <div>&nbsp;</div>
              <img class="post-project__image" src="assets/images/Logo_Separator.png" alt="{{ 'home.SEO.alt.logo-sep' | translate }}" />
            
            <h4 class="orange">{{ 'ar.block3.title' | translate }}</h4>
            <div class="row">
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                      <img class="post-project__image" src="assets/images/page-ar/augmented-reality-3d-onlineshop.jpg" alt="{{ 'ar.SEO.alt.thumb1' | translate }}" />
                      <span>&nbsp;</span>
                      <h5 class="orange">{{ 'ar.block3.item1.title' | translate }}</h5>
                      <p>{{ 'ar.block3.item1.desc' | translate }}</p>
                      <a href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block3.anchor' | translate }}</a>
                  </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ar/augmented-reality-immobilienplanung.jpg" alt="{{ 'ar.SEO.alt.thumb2' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ar.block3.item2.title' | translate }}</h5>
                      <p>{{ 'ar.block3.item2.desc' | translate }}</p>
                      <a href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ar/augmented-reality-fabrikplanung.jpg" alt="{{ 'ar.SEO.alt.thumb3' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ar.block3.item3.title' | translate }}</h5>
                      <p>{{ 'ar.block3.item3.desc' | translate }}</p>
                      <a href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                      <img class="post-project__image" src="assets/images/page-ar/app-entwicklung.jpg" alt="{{ 'ar.SEO.alt.thumb4' | translate }}" />
                      <span>&nbsp;</span>
                      <h5 class="orange">{{ 'ar.block3.item4.title' | translate }}</h5>
                      <p>{{ 'ar.block3.item4.desc' | translate }}</p>
                      <a href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block3.anchor' | translate }}</a>
                  </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ar/augmented-reality-e-training.jpg" alt="{{ 'ar.SEO.alt.thumb5' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ar.block3.item5.title' | translate }}</h5>
                      <p>{{ 'ar.block3.item5.desc' | translate }}</p>
                      <a href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ar/augmented-reality-produktvisualisierung.jpg" alt="{{ 'ar.SEO.alt.thumb6' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ar.block3.item6.title' | translate }}</h5>
                      <p>{{ 'ar.block3.item6.desc' | translate }}</p>
                      <a href="Kontakt/Augmented-Reality/" class="orange">{{ 'ar.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
            </div>
            <h4>&nbsp;</h4><h4>&nbsp;</h4>
          </div>
          
        </section>
    



    <!-- Page Footer -->
    <app-footer></app-footer>
  </div>