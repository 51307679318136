<div class="page">
    <app-header></app-header>


    <!-- Widerruf -->
    <section class="section-xs bg-default object-wrap">
        <div class="section-md">
            <div class="container">
                <div class="row">

                    <div class="col-md-12">
                        <h5>General information</h5>
                        <p>1. Your personal data within the meaning of Art. 4 No. 1 GDPR (e.g. IP address, name, e-mail address, address and telephone number) will only be processed by us in accordance with the provisions of German data protection law and the European Data Protection Basic Regulation (GDPR). The following regulations inform you about the type, scope and purpose of the collection, processing and use of personal data.</p>
                        <p>2. The processing of personal data within the meaning of Art. 4 No. 2 GDPR is lawful pursuant to Art. 6 GDPR if one of the following conditions is met:</p>
                        <ul class="padding-left-50">
                            <li>a) The data subject has given his/her consent to the processing of his/her personal data for one or more specific purposes;</li>
                            <li>b) the processing is necessary for the performance of a contract to which the data subject is a party or for the implementation of pre-contractual measures taken at the request of the data subject;</li>
                            <li>c) processing is necessary to fulfil a legal obligation to which the controller is subject;</li>
                            <li>d) processing is necessary to protect the vital interests of the data subject or of another natural person;</li>
                            <li>e) processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</li>
                            <li>f) processing is necessary to safeguard the legitimate interests of the controller or of a third party, except where the interests or fundamental rights and freedoms of the data subject, which require the protection of personal data, are overridden, in particular where the data subject is a child.</li>
                        </ul>
                        <p>3.The processing of specific personal data (e.g. health data) within the meaning of Art. 9 para. 1 GDPR is lawful, in particular pursuant to Art. 9 para. 2 GDPR, if one of the following conditions is met:</p>
                        <p>– The person has given his or her express consent;</p>
                        <p>– The processing is necessary for the assertion, exercise or defence of legal claims or for actions of the courts within the framework of their judicial activity.</p>
                        <p>4. There is no automatic decision-making or profiling of personal data within the meaning of Art. 22 GDPR.</p>
                        <p>5. The operator shall ensure the security of the data pursuant to Art. 32 GDPR by means of appropriate technical measures, taking into account the principle of proportionality.</p>
                        <p>6. If, contrary to expectations, data protection is violated, the competent supervisory authority will be notified pursuant to Art. 33 GDPR and the person concerned will be notified pursuant to Art. 34 GDPR.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Scope</h5>
                        <p>This privacy policy applies only to our websites. If you are forwarded to other sites via links on our pages, please inform yourself there about the respective handling of your data.</p>
                    </div>

                    
                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Duration of data storage</h5>
                        <p>The duration of the storage of the data transmitted by them depends on the legal storage obligations. According to commercial and tax laws, invoices must be stored for a period of 10 years. </p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Disclosure of data to third parties</h5>
                        <p>Data transferred within the scope of the contractual relationship will only be passed on to third parties (Art. 4 No. 10 GDPR) if you have expressly given your consent (Art. 4 No. 11 GDPR) or if the transfer is necessary to fulfil the contract. Your consent can be revoked at any time. Data collected by visiting the website will only be collected by third parties who are expressly mentioned below.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Responsible person in the sense of the GDPR</h5>
                        <p>The person responsible in the sense of the data protection basic regulation (GDPR), as well as other data protection laws valid in the European Union and other regulations with data protection-juridical character is:</p>
                        <p><br>3Dscout GmbH<br>Parkstr. 10<br>D-52372 Kreuzau<br>Telephone: +49 2421 2997301<br>Email: info@3dscout.de</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Cookies</h5>
                        <p>We use cookies on our site. These are small files that are automatically created by your browser and stored on your device (PC, laptop, tablet, smartphone, etc.) when you visit our site. Cookies do not cause any damage to your terminal device, do not contain viruses, Trojans or other malware. Information is stored in the cookie, which results in each case in connection with the specifically used terminal device. This does not mean, however, that we will immediately become aware of your identity.</p>
                        <p>The use of cookies serves on the one hand to make the use of our offer more pleasant for you. For example, we use so-called session cookies to recognise that you have already visited individual pages on our website. These are automatically deleted after leaving our site.</p>
                        <p>In addition, we also use temporary cookies to optimise user-friendliness, which are stored on your terminal for a specific period of time. If you visit our site again to make use of our services, it is automatically recognized that you have already been with us and which inputs and settings you have made so that you do not have to enter them again.</p>
                        <p>These cookies enable us to automatically recognize that you have already visited our site when you visit it again. These cookies are automatically deleted after a defined period of time. The data processed by cookies are necessary for the purposes mentioned to safeguard our legitimate interests and those of third parties in accordance with Art. 6 Para. 1 S. 1 lit. f GDPR. Most browsers automatically accept cookies. However, you can configure your browser in such a way that it does not. Cookies are stored on your computer or a message always appears before a new cookie is created. However, if you disable cookies completely, you may not be able to use all the features of our website.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Storage of access data in log files</h5>
                        <p>You can visit our websites without providing any personal information. </p>
                        <p>The provider of these pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:</p>
                        <ul class="padding-left-50">
                            <li>Browser type/ browser version</li>
                            <li>used operating system</li>
                            <li>Referrer URL</li>
                            <li>Hostname of the accessing computer</li>
                            <li>Time of the server request</li>
                        </ul>
                        <p>These data cannot be assigned to specific persons. This data will not be merged with other data sources. We reserve the right to subsequently check this data if we become aware of concrete indications of illegal use.</p>
                        <p>The purpose of the processing results from our legitimate interest within the meaning of Art. 6 Para. 1 S. 1 lit. f) GDPR.</p>
                        <p>A contract for order processing has been concluded with our hoster.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Contact form</h5>
                        <p>If you use the contact form offered on these pages, the information you enter and the attached files will be transmitted and stored for the purpose of answering your request. Data transmitted within the scope of establishing contact will only be forwarded to third parties if you have expressly given your consent or if the forwarding is necessary for the fulfilment of a contractual relationship with you.</p>
                        <p>The lawfulness of the processing results from your consent in accordance with Art. 6 para. 1 sentence 1 lit. a) GDPR.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Inquiry by e-mail, telephone</h5>
                        <p>If you contact us by e-mail or telephone, your request including all personal data (name, request) will be stored and processed by us for the purpose of processing your request. These data will not be passed on without your consent.</p>
                        <p>This data is processed on the basis of Art. 6 Para. 1 lit. b GDPR if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, processing is based on your consent (Art. 6 para. 1 lit. a GDPR) and / or on our legitimate interests (Art. 6 para. 1 lit. f GDPR), as we have a legitimate interest in the effective processing of enquiries addressed to us.</p>
                        <p>The data you send us via contact requests will remain with us until you request deletion, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Security of your data / SSL encryption</h5>
                        <p>In accordance with the legal regulation according to § 13 Abs. 7 TMG (German law) this side uses an SSL coding, to recognize by a lock symbol in the address bar of your browser. Transmitted data cannot be read by third parties if SSL encryption is activated.</p>
                        <p>As a rule, this is a 256-bit encryption. If your browser does not support 256-bit encryption, we use 128-bit v3 technology instead. You can tell whether an individual page of our website is transmitted in encrypted form by the closed representation of the key or lock symbol in the lower status bar of your browser.</p>
                        <p>We also use suitable technical and organisational security measures (TOM) to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorised access by third parties. Our security measures are continuously improved in line with technological developments.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Rights of the user</h5>
                        <p>You can request information about the personal data stored about you at any time and free of charge. Your rights also include the confirmation, correction, restriction, blocking and deletion of such data and the provision of a copy of the data, in a form suitable for transmission, as well as the revocation of a consent given and the objection. Legal storage obligations remain unaffected by this.</p>
                        <p>Their rights arise in particular from the following standards of the GDPR:</p>
                        <ul class="padding-left-50">
                            <li>Article 7 para. 3 - Right to revoke consent under data protection law</li>
                            <li>Article 12 - Transparent information, communication and modalities for exercising the rights of the data subject</li>
                            <li>Article 13 - Obligation to provide information when personal data are collected from the data subject</li>
                            <li>Article 14 - Duty to provide information where personal data have not been collected from the data subject</li>
                            <li>Article 15 - Data subject's right of access, confirmation and provision of a copy of personal data</li>
                            <li>Article 16 - Right to rectification</li>
                            <li>Article 17 - Right to cancellation ("right to be forgotten")</li>
                            <li>Article 18 - Right to limitation of processing</li>
                            <li>Article 19 - Notification obligation in relation to rectification or erasure of personal data or limitation of processing</li>
                            <li>Article 20 - Right to data transferability</li>
                            <li>Article 21 - Right of appeal</li>
                            <li>Article 22 - Right not to be subject to a decision based exclusively on automated processing, including profiling</li>
                            <li>Article 77 - Right to complain to a supervisory authority</li>
                        </ul>
                        <p>In order to exercise your rights (with the exception of Art. 77 GDPR), please contact the office named under the item "Person responsible within the meaning of the GDPR" (e.g. by e-mail)</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Competent supervisory authority:</h5>
                        <p>Landesbeauftragte für Datenschutz und Informationsfreiheit NRW</p>
                        <p>Kavalleriestr. 2-4<br>40213 Düsseldorf</p>
                        <p><br>Telephone: 0211/38424-0<br>Fax: 0211/38424-10<br>E-Mail: poststelle@ldi.nrw.de<br>Homepage: https://www.ldi.nrw.de<br>(Before contacting us, please check the homepage to make sure that the above data is still up to date.)</p>
                    </div>

                </div>
            </div>
        </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
</div>