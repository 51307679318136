<div class="page">
    <app-header></app-header>
    <!-- Swiper-->
    <section>
        <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
            data-loop="false" data-autoplay="5000">
            <div class="swiper-wrapper">
                <div class="swiper-slide" data-slide-bg="assets/images/karriere/Header.jpg">

                </div>

            </div>
            
        </div>
    </section>

    <!-- Orange line under slider -->
    <section class="section-xs bg-accent"></section>
    <!-- Impressive Features-->


    <section class="section-md bg-default">
        <div class="container">
            <div class="row row-50">
                <div class="col-md-12">
                    <h4 class="text-center textTransformNone">{{ 'karriere.title' | translate }}</h4>
                </div>
               <div class="col-md-12">
                <h5 class="text-center textTransformNone"><a href="mailto:career@3dscout.de">{{ 'karriere.subtitle' | translate }}</a></h5>
               </div>
                
            </div>
        </div>
    </section>


    <!-- Page Footer -->
    <app-footer></app-footer>
</div>