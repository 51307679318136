import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';


import { NgxCaptchaModule } from 'ngx-captcha';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { Sub3dDruckComponent } from './sub3d-druck/sub3d-druck.component';
import { SubKleinserienfertigungComponent } from './sub-kleinserienfertigung/sub-kleinserienfertigung.component';
import { SubArComponent } from './sub-ar/sub-ar.component';
import { SubVrComponent } from './sub-vr/sub-vr.component';
import { SubArchiComponent } from './sub-archi/sub-archi.component';
import { SubIngeniComponent } from './sub-ingeni/sub-ingeni.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { CareerComponent } from './career/career.component';
import { ImpressumComponent } from './sub-legal/impressum/impressum.component';
import { AgbComponent } from './sub-legal/agb/agb.component';
import { WiderrufComponent } from './sub-legal/widerruf/widerruf.component';
import { DatenschutzComponent } from './sub-legal/datenschutz/datenschutz.component';

import {CookieService} from 'ngx-cookie-service';
import { I18nModule } from './i18n/i18n.module';
import { LegalnoticeComponent } from './sub-legal/legalnotice/legalnotice.component';
import { GTCComponent } from './sub-legal/gtc/gtc.component';
import { RevocationComponent } from './sub-legal/revocation/revocation.component';
import { DataPrivacyComponent } from './sub-legal/data-privacy/data-privacy.component';
import { HauptseiteComponent } from './home/hauptseite/hauptseite.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    Sub3dDruckComponent,
    SubKleinserienfertigungComponent,
    SubArComponent,
    SubVrComponent,
    SubArchiComponent,
    SubIngeniComponent,
    KontaktComponent,
    CareerComponent,
    ImpressumComponent,
    AgbComponent,
    WiderrufComponent,
    DatenschutzComponent,
    LegalnoticeComponent,
    GTCComponent,
    RevocationComponent,
    DataPrivacyComponent,
    HauptseiteComponent,
   
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ReactiveFormsModule,
    AppRoutingModule,
    NgxCaptchaModule,
    I18nModule

  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
