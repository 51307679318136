<div class="page">
    <app-header></app-header>


    <!-- Widerruf -->
    <section class="section-xs bg-default object-wrap">
        <div class="section-md">
            <div class="container">
                <div class="row">

                    <div class="col-md-12">
                        <h5>Allgemeines</h5>
                        <p>1. Ihre personenbezogenen Daten im Sinne von Art. 4 Nr. 1 DSGVO (z.B. IP-Adresse, Name,
                            E-Mail Adresse, Adresse und Telefonnummer) werden von uns nur gemäß den Bestimmungen des
                            deutschen Datenschutzrechts und unter Berücksichtigung der europäischen
                            Datenschutzgrundverordnung (DSGVO) verarbeitet. Die nachfolgenden Vorschriften informieren
                            Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener
                            Daten.</p>
                        <p>2. Die Verarbeitung im Sinne von Art. 4 Nr. 2 DSGVO von personenbezogenen Daten ist gemäß
                            Art. 6 DSGVO rechtmäßig, wenn eine der folgenden Voraussetzungen vorliegt:</p>
                        <ul class="padding-left-50">
                            <li>a) Die betroffene Person hat ihre Einwilligung zu der Verarbeitung der sie betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke gegeben;</li>
                            <li>b) die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen;</li>
                            <li>c) die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt;</li>
                            <li>d) die Verarbeitung ist erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen;</li>
                            <li>e) die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
                            <li>f) die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt.</li>
                        </ul>
                        <p>3. Die Verarbeitung besonderer personenbezogenen Daten (z.B. Gesundheitsdaten) im Sinne von Art. 9 Abs. 1 DSGVO ist insbesondere gemäß Art. 9 Abs. 2 DSGVO rechtmäßig, wenn eine der folgenden Voraussetzungen vorliegt:</p>
                        <p>– es liegt eine ausdrückliche Einwilligung der Person vor;</p>
                        <p>– die Verarbeitung ist zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder bei Handlungen der Gerichte im Rahmen ihrer justiziellen Tätigkeit erforderlich.</p>
                        <p>4. Eine automatische Entscheidungsfindung oder ein Profiling bezüglich personenbezogener Daten im Sinne von Art. 22 DSGVO findet nicht statt.</p>
                        <p>5. Der Betreiber stellt die Sicherheit der Daten gemäß Art. 32 DSGVO unter Berücksichtigung des Proportionalitätsgrundsatzes durch geeignete technische Maßnahmen sicher.</p>
                        <p>6. Sollte es wider Erwarten zu einer Verletzung des Datenschutzes kommen, wird die zuständige Aufsichtsbehörde gemäß Art. 33 DSGVO, sowie die betroffene Person gemäß Art. 34 DSGVO benachrichtigt.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Geltungsbereich</h5>
                        <p>Diese Datenschutzerklärung bezieht sich nur auf unsere Webseiten. Falls Sie über Links auf unseren Seiten auf andere Seiten weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen Umgang mit Ihren Daten.</p>
                    </div>

                    
                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Dauer der Datenspeicherung</h5>
                        <p>Die Dauer der Aufbewahrung der von ihnen übertragenen Daten richtet sich nach den gesetzlichen Aufbewahrungspflichten. Gemäß der Handels- und Steuergesetze besteht eine Aufbewahrungspflicht von Rechnungen für einen Zeitraum von 10 Jahren.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Weitergabe von Daten an Dritte</h5>
                        <p>Eine Weitergabe von im Rahmen des Vertragsverhältnisses übermittelten Daten an Dritte (Art. 4 Nr. 10 DSGVO), erfolgt nur, wenn Sie ausdrücklich Ihre Einwilligung (Art. 4 Nr. 11 DSGVO) erklärt haben oder die Weitergabe zur Erfüllung des Vertrages erforderlich ist. Die Einwilligung kann jederzeit formlos widerrufen werden. Durch den Besuch der Webseite erhobene Daten werden nur von Dritten erhoben, die weiter unten ausdrücklich genannt werden.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Verantwortlicher im Sinne der DSGVO</h5>
                        <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (DSGVO), sowie sonstiger in der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist:</p>
                        <p><br>3Dscout GmbH<br>Parkstr. 10<br>D-52372 Kreuzau<br>Telefon: +49 2421 2997301<br>Email: info@3dscout.de</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Cookies</h5>
                        <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (PC, Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.</p>
                        <p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</p>
                        <p>Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</p>
                        <p>Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht. Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich. Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Speicherung von Zugriffsdaten in Logfiles</h5>
                        <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen.</p>
                        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                        <ul class="padding-left-50">
                            <li>Browsertyp/ Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                        </ul>
                        <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>
                        <p>Der Zweck der Verarbeitung ergibt sich aus unserem berechtigten Interesse im Sinne von Art. 6 Abs. 1 S. 1 lit. f) DSGVO.</p>
                        <p>Ein Vertrag über die Auftragsverarbeitung wurde mit unserem Hoster geschlossen.</p>
                    </div>

					<div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Google Analytics</h5>
                        <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (“Google”). Google Analytics verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.</p>
                        <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
						<p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; Es wird jedoch darauf hingewiesen, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>
						<p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.</p>
						<p>Die Rechtmäßigkeit der Verarbeitung ergibt sich zur Statistikerhebung aus Art. 6 Abs. 1 Satz 1 lit. f) DSGVO.</p>
						<p>Die Anonymisierungsfunktion von Google Analytics wird benutzt.</p>
						<p>Mit Google Inc. wurde ein Vertrag über die Auftragsverarbeitung geschlossen.</p>
						<p>Weitere Informationen zu den Datenschutzrichtlinien von Google Inc. finden Sie unter http://www.google.de/intl/de/privacy</p>
                    </div>
					
					<div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Google reCAPTCHA</h5>
                        <p>Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
						<p>Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse, Geräte- und Anwendungsdaten, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet und auch ggf. mit sonstigen Dritten geteilt.</p>
						<p>Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet.</p>
						<p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
						<p>Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links: https://policies.google.com/privacy?hl=de</p>
						<p>https://policies.google.com/terms?hl=de</p>
					</div>
						
					<div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Google Webfonts</h5>
                        <p>Auf diesen Internetseiten werden externe Schriften, Google Fonts verwendet. Google Fonts ist ein Dienst der Google Inc. („Google“). Die Einbindung dieser Web Fonts erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA. Hierdurch wird an den Server übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird die IP-Adresse des Browsers des Endgerätes des Besuchers dieser Internetseiten von Google gespeichert.</p>
						<p>Die Rechtmäßigkeit der Verwendung ergibt sich aus Art. 6 Abs. 1 S. 1 lit. f) DSGVO</p>
						<p>Nähere Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie hier abrufen können:</p>
						<p>https://www.google.com/fonts#AboutPlace:about</p>
						<p>https://www.google.com/policies/privacy/</p>
                    </div>
					
					<div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Google Tag Manager</h5>
                        <p>Unsere Webseite benutzt den Google Tag Manager zum Zwecke einer personalisierten, interessen- und standortbezogenen Online-Werbung. Die Option, die IP-Adressen zu anonymisieren, wird beim Google Tag Manager über eine interne Einstellung geregelt, die im Source dieser Seite nicht sichtbar wird. Diese interne Einstellung ist so gesetzt, dass die erforderte Anonymisierung der IP-Adressen erreicht wird.</p>
						<p>Link zum Opt. Out: https://adssettings.google.com/authenticated</p>
						<p>Weitere Hinweise zu den Bestimmungen von Google finden Sie unter https://policies.google.com/privacy?hl=de.</p>
						<p></p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Kontaktformular</h5>
                        <p>Bei Verwendung des auf diesen Seiten angebotenen Kontaktformulars erfolgt eine Übertragung und Speicherung der von Ihnen eingegebenen Informationen und beigefügten Dateien zum Zwecke der Beantwortung Ihres Anliegens. Eine Weitergabe von im Rahmen der Kontaktaufnahme übermittelten Daten an Dritte erfolgt nur, wenn Sie ausdrücklich Ihre Zustimmung erklärt haben oder die Weitergabe zur Erfüllung eines Vertragsverhältnisses mit Ihnen erforderlich ist.</p>
                        <p>Die Rechtmäßigkeit der Verarbeitung ergibt sich aus Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a) DSGVO.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Anfrage per E-Mail, Telefon</h5>
                        <p>Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.</p>
                        <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
                    </div>
					
					<div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Social-Media Links</h5>
						<p>Wir haben bei den über Links von dieser Website zu erreichenden Drittanbietern eigene Social-Media-Seiten. Durch die Nutzung der Links gelangen Sie auf die jeweiligen Internetseiten der Drittanbieter (z.B. LinkedIn, Xing, Facebook, Instagram) und können unsere Inhalte auch teilen. Hierbei findet durch den Aufruf unserer Website kein Datentransfer statt. Sobald Sie die Seite der Drittanbieter aufgerufen haben, befinden Sie sich im Verantwortungsbereich des jeweiligen Drittanbieters, so dass auch deren Datenschutzerklärung bzw. deren Erklärungen zur Datenverwendung gelten. Wir haben hierauf keinen Einfluss, wir empfehlen jedoch zur Vermeidung einer unnötigen Datenweitergabe vor der Nutzung eines entsprechenden Links sich selbst bei dem jeweiligen Drittanbieter auszuloggen, damit nicht schon durch die Verwendung des Links u.U. Nutzungsprofile durch den Drittanbieter erstellt werden können.</p>
                    </div>
					
					<div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Facebook Pixel</h5>
						<p>Wir setzen den “Conversion-Pixel“ bzw. Besucheraktions-Pixel der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA (“Facebook”) ein. Durch den Aufruf dieses Pixels aus Ihrem Browser kann Facebook in der Folge erkennen, ob eine Facebook-Werbeanzeige erfolgreich war, also z.B. zu einem online-Kaufabschluss geführt hat. Wir erhalten von Facebook hierzu ausschließlich statistische Daten ohne Bezug zu einer konkreten Person. So können wir die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke erfassen. Insbesondere falls Sie bei Facebook angemeldet sind, verweisen wir im Übrigen auf deren Datenschutzinformationen https://www.facebook.com/ads/preferences/(externer Link).</p>
						<p>Bitte gehen Sie auf https://www.facebook.com/ads/preferences/ , wenn Sie Ihre Einwilligung zu Conversion Pixel widerrufen möchten.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Sicherheit Ihrer Daten / SSL-Verschlüsselung</h5>
                        <p>Im Einklang mit der gesetzlichen Regelung nach § 13 Abs. 7 TMG verwendet diese Seite eine SSL-Verschlüsselung, zu erkennen an einem Schloss-Symbol in der Adressleiste Ihres Browsers. Übermittelte Daten können bei aktivierter SSL-Verschlüsselung nicht von Dritten mitgelesen werden.</p>
                        <p>In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>
                        <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen (TOM), um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Rechte des Nutzers</h5>
                        <p>Sie können jederzeit und unentgeltlich Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten verlangen. Ihre Rechte umfassen dabei auch eine Bestätigung, Berichtigung, Beschränkung, Sperrung und Löschung von solchen Daten und die Zurverfügungstellung einer Kopie der Daten, in einer zur Übertragung geeigneten Form, sowie den Widerruf einer erteilten Einwilligung und den Widerspruch. Gesetzliche Aufbewahrungspflichten bleiben hiervon unberührt.</p>
                        <p>Ihre Rechte ergeben sich dabei im Einzelnen insbesondere aus den folgenden Normen der DSGVO:</p>
                        <ul class="padding-left-50">
                            <li>Artikel 7 Abs. 3 – Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</li>
                            <li>Artikel 12 – Transparente Information, Kommunikation und Modalitäten für die Ausübung der Rechte der betroffenen Person</li>
                            <li>Artikel 13 – Informationspflicht bei Erhebung von personenbezogenen Daten bei der betroffenen Person</li>
                            <li>Artikel 14 – Informationspflicht, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben wurden</li>
                            <li>Artikel 15 – Auskunftsrecht der betroffenen Person, Recht auf Bestätigung und Zurverfügungstellung einer Kopie der personenbezogenen Daten</li>
                            <li>Artikel 16 – Recht auf Berichtigung</li>
                            <li>Artikel 17 – Recht auf Löschung („Recht auf Vergessenwerden“)</li>
                            <li>Artikel 18 – Recht auf Einschränkung der Verarbeitung</li>
                            <li>Artikel 19 – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung</li>
                            <li>Artikel 20 – Recht auf Datenübertragbarkeit</li>
                            <li>Artikel 21 – Widerspruchsrecht</li>
                            <li>Artikel 22 – Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden</li>
                            <li>Artikel 77 – Recht auf Beschwerde bei einer Aufsichtsbehörde</li>
                        </ul>
                        <p>Zur Ausübung Ihrer Rechte (mit Ausnahme von Art. 77 DSGVO) wenden Sie sich bitte an die unter dem Punkt „Verantwortlicher im Sinne der DSGVO“ (z.B. per E-Mail) genannte Stelle.</p>
                    </div>

                    <div class="col-md-12">
                        <p>&nbsp;</p>
                        <h5>Zuständige Aufsichtsbehörde:</h5>
                        <p>Landesbeauftragte für Datenschutz und Informationsfreiheit NRW</p>
                        <p>Kavalleriestr. 2-4<br>40213 Düsseldorf</p>
                        <p><br>Telefon: 0211/38424-0<br>Fax: 0211/38424-10<br>E-Mail: poststelle@ldi.nrw.de<br>Homepage: https://www.ldi.nrw.de<br>(Es wird darum gebeten vor Kontaktaufnahme über die Homepage zu überprüfen, ob die o.g. Daten noch aktuell sind)</p>
                    </div>

                </div>
            </div>
        </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
</div>