<!-- Page-->
<div class="page">
  <app-header></app-header>
  <!-- Swiper-->
  <section>
    <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
      data-loop="false" data-autoplay="5000">
      <div class="swiper-wrapper">
        <div class="swiper-slide" data-slide-bg="assets/images/page-3d-druck/Header.jpg">
          <div class="swiper-slide-caption text-left">
            <div class="container">
              <div class="row justify-content-center justify-content-xxl-end">
                <div class="col-lg-10 col-xxl-6">
                  <h1 data-caption-animate="fadeInUpSmall">{{ '3d.slider.title' | translate }}</h1>
                  <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">
                    {{ '3d.slider.subtitle' | translate }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>

  <!-- Orange line under slider -->
  <section class="section-xs bg-accent"></section>
  <!-- Impressive Features-->


  <!-- Augmented Reality -->
  <section class="section-xs bg-default object-wrap">
    <div class="section-md">
      <div class="container">
        <div class="row">

          <div class="col-md-6">
            <h4 class="heading-decorated">{{ '3d.block1.title' | translate }}</h4>
            <p>{{ '3d.block1.paraLeft.text1' | translate }}<a class="orange"
                href="Produktentwicklung/">{{ '3d.block1.paraLeft.orange1' | translate }}</a>{{ '3d.block1.paraLeft.text2' | translate }}<a
                class="orange"
                href="Architekturleistungen/">{{ '3d.block1.paraLeft.orange2' | translate }}</a>{{ '3d.block1.paraLeft.text3' | translate }}
            </p>
          </div>

          <div class="col-md-6">
            <h4 class="heading-decorated-blank">&nbsp;</h4>
            <p>{{ '3d.block1.paraRight.text1' | translate }}<a class="orange"
                href="Auftragsfertigung">{{ '3d.block1.paraRight.orange1' | translate }}</a>{{ '3d.block1.paraRight.text2' | translate }}<a
                class="orange"
                href="Auftragsfertigung">{{ '3d.block1.paraRight.orange2' | translate }}</a>{{ '3d.block1.paraRight.text3' | translate }}<a
                class="orange" href="Kontakt/3D-Druck/">{{ '3d.block1.paraRight.orange3' | translate }}</a></p>
          </div>
        </div>
      </div>
    </div>

  </section>

  <!-- DESHALB ENTSCHEIDEN SICH UNSERE KUNDEN FÜR UNS -->
  <section class="bg-default object-wrap">
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h4 class="heading-decorated">{{ '3d.block2.title' | translate }}</h4>
            <ul class="c">
              <li>
                <p>&#9673;&nbsp;&nbsp;{{ '3d.block2.paraLeft.text1' | translate }}</p>
              </li>
              <li>
                <p>&#9673;&nbsp;&nbsp;{{ '3d.block2.paraLeft.text2' | translate }}</p>
              </li>
              <li>
                <p>&#9673;&nbsp;&nbsp;{{ '3d.block2.paraLeft.text3' | translate }}</p>
              </li>
            </ul>

          </div>
          <div class="col-lg-6">

            <div class="col-md-12">
              <!-- Post project-->
              <article class="post-project">

                <figure>
                  <video controls autoplay="autoplay" loop width="530" height="350">
                    <source src="assets/images/page-3d-druck/3d-druckdienst-rapid-prototyping.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>

                </figure>


              </article>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- UNSERE 3D-DRUCKVERFAHREN -->
  <!--text block-->
  <section class="section-xs bg-default object-wrap text-center">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xl-12">
          <div>&nbsp;</div>
          <img class="post-project__image" src="assets/images/Logo_Separator.png" alt="{{ 'home.SEO.alt.logo-sep' | translate }}" />
          <h4 class="orange">{{ '3d.block3.title' | translate }}</h4>
          <p>&nbsp;</p>
          <div class="">
            <div class="container">

              <div class="row">
                <div class="col-lg-6">

                  <div class="col-md-10">
                    <!-- Post project-->
                    <article class="post-project">

                      <figure>
                        <img class="post-project__image" src="assets/images/page-3d-druck/sls-druckverfahren.jpg" alt="{{ '3d.SEO.alt.thumb1' | translate }}" />

                      </figure>


                    </article>
                  </div>


                </div>
                <div class="col-lg-6">
                  <h5 class="text-bold">{{ '3d.block3.SLS.title' | translate }}</h5>
                  <p class="justify-3d-text">{{ '3d.block3.SLS.text1' | translate }}<a class="orange"
                      href="Auftragsfertigung">{{ '3d.block3.SLS.orange1' | translate }}</a>{{ '3d.block3.SLS.text2' | translate }}<a
                      href="Kontakt/3D-Druck/" class="orange">{{ '3d.block3.SLS.anchor' | translate }}</a></p>

                </div>

                <div class="col-lg-12 top-bottom-border">

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title1' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title2' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                  </div>

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title3' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title4' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="width-100">&nbsp;</div><div>&nbsp;</div>
              </div>

              <div class="row">
                <div class="col-lg-6">

                  <div class="col-md-10">
                    <!-- Post project-->
                    <article class="post-project">

                      <figure>
                        <img class="post-project__image" src="assets/images/page-3d-druck/slm-druckverfahren.jpg" alt="{{ '3d.SEO.alt.thumb2' | translate }}" />

                      </figure>


                    </article>
                  </div>


                </div>
                <div class="col-lg-6">
                  <h5 class="text-bold">{{ '3d.block3.SLM.title' | translate }}</h5>
                  <p class="justify-3d-text">{{ '3d.block3.SLM.text1' | translate }}<span
                      class="italic-text">{{ '3d.block3.SLM.italic1' | translate }}</span>{{ '3d.block3.SLM.text2' | translate }} <a
                      href="Kontakt/3D-Druck/" class="orange">{{ '3d.block3.SLS.anchor' | translate }}</a></p>
                </div>

                <div class="col-lg-12 top-bottom-border">

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title1' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title2' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                  </div>

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title3' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title4' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="width-100">&nbsp;</div><div>&nbsp;</div>
              </div>

              <div class="row">
                <div class="col-lg-6">

                  <div class="col-md-10">
                    <!-- Post project-->
                    <article class="post-project">

                      <figure>
                        <img class="post-project__image" src="assets/images/page-3d-druck/sla-druckverfahren.jpg" alt="{{ '3d.SEO.alt.thumb3' | translate }}" />

                      </figure>


                    </article>
                  </div>


                </div>
                <div class="col-lg-6">
                  <h5 class="text-bold">{{ '3d.block3.SLA.title' | translate }}</h5>
                  <p class="justify-3d-text">{{ '3d.block3.SLA.text1' | translate }}<a
                      class="orange" href="Auftragsfertigung">{{ '3d.block3.SLA.orange1' | translate }}</a>{{ '3d.block3.SLA.text2' | translate }} <a
                      href="Kontakt/3D-Druck/" class="orange">{{ '3d.block3.SLA.anchor' | translate }}</a></p>
                </div>

                <div class="col-lg-12 top-bottom-border">

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title1' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title2' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                      </div>
                    </div>

                  </div>

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title3' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title4' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="width-100">&nbsp;</div><div>&nbsp;</div>
              </div>

              <div class="row">
                <div class="col-lg-6">

                  <div class="col-md-10">
                    <!-- Post project-->
                    <article class="post-project">

                      <figure>
                        <img class="post-project__image" src="assets/images/page-3d-druck/polyjet-multijet-druckverfahren.jpg" alt="{{ '3d.SEO.alt.thumb4' | translate }}" />

                      </figure>


                    </article>
                  </div>


                </div>
                <div class="col-lg-6">
                  <h5 class="text-bold">{{ '3d.block3.JET.title' | translate }}</h5>
                  <p class="justify-3d-text">{{ '3d.block3.JET.text1' | translate }} <a href="#"
                      class="orange" href="Kontakt/3D-Druck/">{{ '3d.block3.JET.anchor' | translate }}</a></p>
                </div>

                <div class="col-lg-12 top-bottom-border">

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title1' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title2' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                  </div>

                  <div class="row reset-margin-top">

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title3' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                        <div class="square-off"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 row reset-margin-top">
                      <div class="col-lg-6 rating-div">
                        <p class="rating-title">{{ '3d.block3.rating.title4' | translate }}</p>
                      </div>
                      <div class="col-lg-6 rating-parent">
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                        <div class="square-on"></div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="width-100">&nbsp;</div><div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </section>




  <app-footer></app-footer>
</div>