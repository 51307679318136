import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookService } from 'src/app/services/cook.service';

@Component({
  selector: 'app-gtc',
  templateUrl: './gtc.component.html',
  styleUrls: ['./gtc.component.css']
})
export class GTCComponent implements OnInit {

  constructor(public translate: TranslateService, public cookie: CookService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.translate.use('en');
    this.cookie.setCookie('lang','en');
  }
}
