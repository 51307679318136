<div class="page">
    <app-header></app-header>

  
    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
      <div class="section-md">
        <div class="container">
          <div class="row">

            <div class="col-md-12">
              <h5>§ 1 Vertragsgegenstand / Vertragsschluss</h5>
              <p>(1) Der Vertragsschluss kommt zwischen dem Anbieter der Firma 3Dscout GmbH, Parkstr. 10, 52372 Kreuzau (nachfolgend: „Auftragnehmer“) und dem Kunden (nachfolgend: „Auftraggeber“) durch Annahme des vom Auftraggeber übermittelten Angebotes zustande. Die Wirksamkeit des Vertragsschlusses setzt die Textform voraus. Der Vertragstext wird unter Wahrung der datenschutzrechtlichen Bestimmungen gespeichert. Der Vertragsschluss erfolgt in deutscher Sprache.</p>
              <p>(2) Vorherige Angebote sind freibleibend. Angebote sind für einen Zeitraum von 14 Tagen gültig. Sämtliche Vereinbarungen werden erst durch Bestätigung in Textform verbindlich, die stets als kaufmännisches Bestätigungsschreiben gilt, sofern der Auftraggeber Unternehmer ist.</p>
              <p>(3) Der Auftraggeber ist Verbraucher im Sinne von § 13 BGB, soweit der Zweck der georderten Lieferungen und Leistungen nicht überwiegend seiner gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden kann. Dagegen ist Unternehmer gemäß § 14 BGB jede natürliche oder juristische Person oder rechtsfähige Personengesellschaft, die beim Abschluss des Vertrags in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.</p>
              <p>(4) Der Auftragnehmer ist berechtigt Leistungen Dritter in Anspruch zu nehmen, um die Verpflichtungen aus dem Vertrag zu erfüllen, ohne dass es einer Zustimmung des Auftraggebers bedarf.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 2 Umfang der Leistungen</h5>
                <p>(1) Die vom Auftragnehmer zu erbringenden Leistungen richten sich nach den individuellen Vereinbarungen der Parteien, die sich aus dem Angebot des Auftragnehmers ergeben.</p>
                <p>(2) Im Vorfeld nicht vereinbarte Leistungen sind von dieser Vereinbarung und der vereinbarten Vergütung nicht umfasst. Derartige Leistungen müssen gesondert in Auftrag gegeben und vergütet werden.</p>
                <p>(3) Nutzungsrechte an externen Produkten werden vom Auftraggeber unabhängig und rechtlich getrennt von den angebotenen Leistungen erworben. Die Lizenzbedingungen des jeweiligen Herstellers der Software oder eines geschützten Werkes sind zu beachten.</p>
                <p>(4) Hinsichtlich der vom Auftragnehmer erstellten Inhalte, wird dem Auftraggeber ein zeitlich und inhaltlich unbeschränktes nicht ausschließliches Nutzungsrecht gewährt.</p>
                <p>(5) Der Auftraggeber ist selbst dafür verantwortlich ggf. erforderliche Abspielsoftware bereit zu halten, sofern nicht anders vereinbart.</p>
                <p>(6) Der Auftraggeber hat keinen Anspruch auf Herausgabe des Rohmaterials, soweit nicht anders vereinbart. Der Auftragnehmer stellt das Material in einem gängigen Dateiformat zur Verfügung, sofern digitale Leistungen geschuldet sind.</p>
                <p>(7) Soweit Ausdrucke oder Datenträger (z.B. CD-ROM, Speicherkarte, USB-Stick) per Post versendet werden, trägt der Auftraggeber das Versendungsrisiko, wenn dieser Unternehmer ist.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 3 Gewährleistung, Garantie</h5>
                <p>Sofern es sich bei der erbrachten Leistung um einen Werkvertrag handelt oder der Auftraggeber Waren erwirbt, gilt Folgendes:</p>
                <p>(1) Der Auftragnehmer haftet für Mängel des Werkes/der Sache nach den hierfür geltenden gesetzlichen Vorschriften, insbesondere §§ 434 ff. BGB für Waren und §§ 634 ff. BGB für Werke. Gegenüber Unternehmern beträgt die Gewährleistungsfrist auf vom Auftragnehmer gelieferte Werke/Waren 12 Monate. Bei gebrauchten Waren wird die Gewährleistungszeit auf 12 Monate verkürzt.</p>
                <p>(2) Ist der Auftraggeber Unternehmer, sind zwecks Erhaltung von Mängelansprüchen des Auftraggebers Mängel dem Auftragnehmer unverzüglich, spätestens jedoch innerhalb von zwei Wochen nach Lieferung schriftlich mitzuteilen. Die mangelhaften Werke/Waren sind in dem Zustand, in dem sie sich im Zeitpunkt der Feststellung des Mangels befinden, zur Besichtigung durch den Auftragnehmer bereit zu halten.</p>
                <p>(3) Bei mit dem 3D Drucker hergestellten Gegenständen wird keine bestimmte Standfestigkeit und Haltbarkeit gewährleistet. Aufgrund des Ausgangsmaterials für den Druck ist die Haltbarkeit begrenzt. Der Auftragnehmer rät dringend davon ab, 3D Druckmodelle in sicherheitsrelevanten Bereichen einzusetzen.</p>
                <p>(4) Eine zusätzliche Garantie besteht bei den vom Auftragnehmer gelieferten Werken/Waren nur, wenn diese ausdrücklich in der Auftragsbestätigung zu dem jeweiligen Artikel abgegeben wurde.</p>
            </div>  
            
            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 3a Eigentumsvorbehalt</h5>
                <p>(1) Bis zur vollständigen Bezahlung verbleiben die gelieferten Waren im Eigentum des Auftragnehmers.</p>
                <p>(2) Der Auftragnehmer behält sich gegenüber Unternehmern bis zur vollständigen Begleichung aller Forderungen aus einer laufenden Geschäftsbeziehung das Eigentum an der gelieferten Ware vor.</p>
                <p>(3) Der Auftraggeber ist zur Weiterveräußerung der Vorbehaltsware im ordnungsgemäßen Geschäftsbetrieb berechtigt, sofern er Unternehmer ist und eine schriftliche Zustimmung des Auftragnehmers vorliegt. Alle hieraus entstehenden Forderungen gegen Dritte tritt der Auftraggeber in Höhe des vollständigen Rechnungswertes vorzeitig an den Auftragnehmer ab. Die Verarbeitung der Ware hat auf die Wirksamkeit der Abtretung keinen Einfluss. Der Auftraggeber bleibt jedoch zur Einziehung seiner Forderungen auch nach der Abtretung ermächtigt, ohne dass die Befugnis des Auftragnehmers zur Einziehung der Forderungen davon berührt wird. Der Auftragnehmer sieht von einer Einziehung der Forderungen solange kein Antrag auf Eröffnung eines Insolvenzverfahrens gestellt ist, und/oder der Auftraggeber seine Zahlungsverpflichtungen dem Auftragnehmer abschlagsfrei erfüllt, und/oder nicht in Zahlungsverzug gerät.</p>

            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 4 Pflichten des Auftraggebers</h5>
                <p>(1) Der Auftraggeber verpflichtet sich an der Erfüllung des Vertrages dergestalt mitzuwirken, dass der Auftragnehmer die Arbeiten reibungslos durchführen kann. Dem Auftragnehmer sind insbesondere alle Informationen wahrheitsgemäß und vollständig zu übermitteln, die für die Erbringung der Leistung erforderlich sind. Schäden aufgrund von unzureichender Informationen gehen zu Lasten des Auftraggebers (auf § 6 wird verwiesen).</p>
                <p>(2) Der Auftraggeber ist selbst für die Einhaltung der datenschutzrechtlichen und persönlichkeitsrechtlichen Vorschriften verantwortlich. Der Auftraggeber versichert etwaig erforderliche Zustimmungen einzuholen. Der Auftraggeber stellt den Auftragnehmer von jeglichen Ansprüchen frei, die Dritte an diesen wegen der Verletzung solcher Rechte stellen.</p>
                <p>(3) Der Auftraggeber ist für die Einhaltung von gesetzlichen Bestimmungen nach Erhalt der Leistung/Ware selbst verantwortlich. Dies gilt insbesondere auch für die rechtlichen Anforderungen in bestimmten Ländern. Die Prüfung auf Rechtskonformität ist nicht Gegenstand der geschuldeten Leistung, soweit nicht ausdrücklich vereinbart.</p>
                <p>(4) Treten bei Nutzung des Vertragsgegenstandes Fehler auf, so ist der Auftraggeber verpflichtet, diese unverzüglich in schriftlicher Form dem Auftragnehmer zu melden und die für die Fehlerbeseitigung zweckdienlichen Informationen anzugeben. Dazu gehört insbesondere die Auflistung der Reihenfolge von Programmabläufen, das Reproduzieren der mit dem Vertragsgegenstand ausgeübten Tätigkeiten etc. Der Auftraggeber wird auf Wunsch des Auftragnehmers den gemeldeten Fehler aufzeichnen und ihm diese Dokumentation zur Verfügung stellen.</p>
                <p>(5) Der Auftraggeber versichert, dass von ihm übermittelte Inhalte frei von Rechten Dritter sind und stellt den Auftragnehmer von jeglichen Ansprüchen diesbezüglich frei.</p>
                <p>(6) Der Auftraggeber ist verpflichtet die ausgeführten Leistungen des Auftragnehmers zu prüfen und ggf. erforderliche Validierungen vorzunehmen.</p>
                <p>(7) Verstößt der Auftraggeber gegen die Pflichten, ist der Auftragnehmer berechtigt den entstandenen Mehraufwand (z.B. zusätzliche Arbeitskosten) in Rechnung zu stellen.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 5 Vergütung und Zahlungsabwicklung</h5>
                <p>(1) Der Auftragnehmer berechnet seine Leistungen auf Grundlage eines Pauschalhonorars, sofern nicht anders vereinbart. Arbeiten an Wochenenden und Feiertagen werden nur nach Vereinbarung der Parteien erbracht. Die vereinbarten Preise gelten je Mitarbeiter, soweit nicht abweichend vereinbart.</p>
                <p>(2) Der Auftragnehmer verlangt bei Vertragsschluss eine Anzahlung in Höhe von 50% der Auftragssumme. Der Restbetrag ist mit Fertigstellung fällig. Beim Kauf einer Ware ist der komplette Betrag mit dem auf der Rechnung angegebenen Zahlungsziel fällig, soweit nicht abweichend vereinbart.</p>
                <p>(3) Die Zahlungsmethode richtet sich nach den Vereinbarungen der Parteien.</p>
                <p>(4) Ist eine Zahlung per Rechnung vereinbart und ist die Fälligkeit der Zahlung nach dem Kalender bestimmt, so kommt der Auftraggeber bereits durch Versäumung des Termins in Verzug. In diesem Fall hat er dem Auftragnehmer für das Jahr Verzugszinsen i.H.v. 5 Prozentpunkten über dem Basiszinssatz zu zahlen. Ist der Auftraggeber Unternehmer, so belaufen sich die Verzugszinsen auf 9 Prozentpunkte über dem Basiszinssatz. Die Verpflichtung des Auftraggebers zur Zahlung von Verzugszinsen schließt die Geltendmachung weiterer Verzugsschäden durch den Auftragnehmer nicht aus.</p>
                <p>(5) Der Auftragnehmer ist zum Rücktritt vom Vertrag berechtigt, wenn sich der Auftraggeber in Verzug befindet.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 5a Verhinderung des Auftragnehmers</h5>
                <p>(1) Soweit der Auftragnehmer krankheitsbedingt oder aus Gründen der höheren Gewalt an der Leistungserbringung gehindert ist, so verlängert sich die vereinbarte Lieferzeit/Leistungszeit um die Dauer der Verhinderung. Ein Schadensersatzanspruch besteht hierbei nicht.</p>
                <p>(2) Der Auftragnehmer ist nicht verpflichtet aber berechtigt während der Verhinderung die Leistung durch einen Dritten erbringen zu lassen. Im Falle der Leistungserbringung durch einen Dritten entstehen dem Auftraggeber keine Mehrkosten. Die vereinbarte Abrechnung der Parteien kommt zur Anwendung.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 5b Kündigung, Stornierung</h5>
                <p>(1) Das Recht zur ordentlichen Kündigung wird ausgeschlossen. Das Recht zur außerordentlichen Kündigung wegen eines wichtigen Grundes bleibt unberührt.</p>
                <p>(2) Die Kündigung bedarf zur Wirksamkeit der Textform.</p>
                <p>(3) Maßgeblich für den Zeitpunkt der Kündigung ist der Eingang der Kündigungserklärung beim Empfänger</p>
                <p>(4) Eine Stornierung des Vertrages wird ausgeschlossen. Das Widerrufsrecht des Auftraggebers bleibt unberührt, wenn dieser Verbraucher ist. </p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 6 Haftung</h5>
                <p>(1) Ansprüche des Auftraggebers auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche des Auftraggebers aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des Auftragnehmers, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.</p>
                <p>(2) Bei der Verletzung wesentlicher Vertragspflichten haftet der Auftragnehmer nur auf den vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich um Schadensersatzansprüche des Auftraggebers aus einer Verletzung des Lebens, des Körpers oder der Gesundheit.</p>
                <p>(3) Gegenüber Unternehmern wird die Haftung auf entgangenen Gewinn ausgeschlossen. Abs. 1 bleibt unberührt.</p>
                <p>(4) Die Einschränkungen gelten auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen des Auftragnehmers, wenn Ansprüche direkt gegen diese geltend gemacht werden.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 7 Geheimhaltung</h5>
                <p>(1) „Vertrauliche Informationen“ sind alle der jeweils anderen Partei zur Kenntnis gelangenden Informationen und Unterlagen über Geschäftsvorgänge der betroffenen anderen Partei.</p>
                <p>(2) Beide Parteien verpflichten sich, über die jeweils andere Partei betreffende vertrauliche Informationen Stillschweigen zu bewahren und diese nur für die Durchführung dieses Vertrages und den damit verfolgten Zweck zu verwenden.</p>
                <p>(3) Beide Parteien verpflichten sich, die Geheimhaltungspflicht sämtlichen Angestellten, und/oder Dritten, die Zugang zu den vorbezeichneten Geschäftsvorgängen haben, aufzuerlegen.</p>
                <p>(4) Die Geheimhaltungspflicht nach Abs. 2 gilt nicht für Informationen,</p>
                <ul class="padding-left-50">
                        <li>a) die der jeweils anderen Partei bei Abschluss des Vertrags bereits bekannt waren,</li>
                        <li>b) die zum Zeitpunkt der Weitergabe durch den Auftragnehmer bereits veröffentlicht waren, ohne dass dies von einer Verletzung der Vertraulichkeit durch die jeweils andere Partei herrührt,</li>
                        <li>c) die die jeweils andere Partei ausdrücklich schriftlich zur Weitergabe freigegeben hat,</li>
                        <li>d) die die jeweils andere Partei rechtmäßig und ohne die Vertraulichkeit betreffende Einschränkung aus anderen Quellen erhalten hat, sofern die Weitergabe und Verwertung dieser Vertraulichen Informationen weder vertragliche Vereinbarungen noch gesetzliche Vorschriften oder behördliche Anordnungen verletzen,</li>
                        <li>e) die die jeweils andere Partei selbst ohne Zugang zu den vertraulichen Informationen des Auftraggebers entwickelt hat,</li>
                        <li>f) die aufgrund gesetzlicher Auskunfts-, Unterrichtungs- und/oder Veröffentlichungspflichten oder behördlicher Anordnung offengelegt werden müssen. Soweit zulässig, wird die hierzu verpflichtete Partei die jeweils andere Partei hierüber so früh wie möglich informieren und sie bestmöglich dabei unterstützen, gegen die Pflicht zur Offenlegung vorzugehen.</li>
                </ul>
                <p>(5) Auf eine ggf. gesondert abzuschließende Geheimhaltungsvereinbarung wird verwiesen. Dies wird Bestandteil des Vertrages. Soweit diese von § 7 abweicht, geht diese den AGB in diesem Punkt vor.</p> 
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 8 Datenschutz</h5>
                <p>(1) Der Auftraggeber ist mit der Speicherung persönlicher Daten im Rahmen der Geschäftsbeziehung mit dem Auftragnehmer, unter Beachtung der Datenschutzgesetze, insbesondere dem BDSG und der DSGVO einverstanden. Eine Weitergabe von Daten an Dritte erfolgt nicht, soweit dies nicht zur Durchführung des Vertrages erforderlich ist oder eine Einwilligung vorliegt.</p>
                <p>(2) Soweit der Auftragnehmer bei der Durchführung des Vertrages personenbezogene Daten Dritter (z.B. Mitarbeiter, Auftraggebern) verarbeiten muss, versichert der Auftraggeber, dass er die Einwilligung der Betroffenen eingeholt hat und stellt den Auftragnehmer von jeglichen Ansprüchen diesbezüglich frei.</p>
                <p>(3) Sofern zwischen den Parteien eine Fernwartung vereinbart wird, erklärt der Auftraggeber seine jederzeit widerrufliche Einwilligung, wonach der Auftragnehmer sich in Absprache mit und in Anwesenheit des Auftraggebers in sein Computersystem einwählen kann um eine Wartung durchzuführen.</p>
                <p>(4) Die Rechte des Auftraggebers bzw. des Betroffenen ergeben sich dabei im Einzelnen insbesondere aus den folgenden Normen der DSGVO:</p>
                    <ul class="padding-left-50">
                        <li>Artikel 7 Abs. 3 – Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</li>
                        <li>Artikel 15 – Auskunftsrecht der betroffenen Person, Recht auf Bestätigung und Zurverfügungstellung einer Kopie der personenbezogenen Daten</li>
                        <li>Artikel 16 – Recht auf Berichtigung</li>
                        <li>Artikel 17 – Recht auf Löschung („Recht auf Vergessenwerden“)</li>
                        <li>Artikel 18 – Recht auf Einschränkung der Verarbeitung</li>
                        <li>Artikel 20 – Recht auf Datenübertragbarkeit</li>
                        <li>Artikel 21 – Widerspruchsrecht</li>
                        <li>Artikel 22 – Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden</li>
                        <li>Artikel 77 – Recht auf Beschwerde bei einer Aufsichtsbehörde</li>
                    </ul>
                <p>(5) Zur Ausübung der Rechte, wird der Auftraggeber bzw. der Betroffene gebeten sich per E-Mail an den Auftragnehmer oder bei Beschwerde an die zuständige Aufsichtsbehörde zu wenden.</p>
                <p>(6) Der Auftragnehmer versichert technisch-organisatorische Maßnahmen getroffen zu haben, um den Schutz personenbezogener Daten zu gewährleisten. Im Übrigen wird auf die Datenschutzerklärung des Auftragnehmers verwiesen.</p>
                <p>(7) Der Auftraggeber willigt ausdrücklich dazu ein, dass objektbezogene Daten für die Durchführung des Vertrages in Drittländer außerhalb der Europäischen Union übermittelt werden können. Personenbezogene Daten sind hiervon jedoch nicht betroffen.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 9 Referenznennung / Kennzeichnung</h5>
                <p>(1) Der Auftragnehmer ist berechtigt auf der Webseite und in sozialen Netzwerken den Auftraggeber unentgeltlich als Referenz anzugeben, soweit nicht anders ausdrücklich vereinbart.</p>
                <p>(2) Der Auftragnehmer ist berechtigt auf erstellten Inhalten einen Hinweis auf das Unternehmen des Auftragnehmers unterzubringen. Dies beinhaltet auch die Einbettung eines Links der Webseite des Auftragnehmers, sofern möglich. Eine abweichende Vereinbarung bedarf der Textform.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 10 Streitschlichtung</h5>
                <p>(1) Die Plattform der EU zur außergerichtlichen Online-Streitbeilegung ist unter folgender Internetadresse erreichbar:
                    <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
                <p>(2) Der Auftragnehmer ist weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 11 Schlussbestimmungen</h5>
                <p>(1) Die Geschäftsbeziehungen zwischen den Parteien unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>
                <p>(2) Gerichtsstand und Erfüllungsort ist der Sitz des Auftragnehmers in Kreuzau (NRW, Deutschland), soweit der Auftraggeber Kaufmann im Sinne des HGB oder eine juristische Person des öffentlichen Rechts oder von öffentlich-rechtlichem Sondervermögen ist. Dasselbe gilt, wenn der Auftraggeber keinen allgemeinen Gerichtsstand in Deutschland hat oder Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt sind.</p>
                <p>(3) Mit Vertragsschluss erklärt sich der Auftraggeber mit den geltenden Allgemeinen Geschäftsbedingungen einverstanden.</p>
                <p>(4) Nebenabreden zum Vertrag bestehen nicht. Sofern Nebenabreden vereinbart werden, bedürfen diese zur Wirksamkeit der Textform. Vereinbarungen in schriftlichen Angeboten der Parteien gehen diesen AGB vor, sofern Widersprüche zu den AGB bestehen.</p>
                <p>(5) Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen unwirksam sein oder werden, so wird die Gültigkeit der Allgemeinen Geschäftsbedingungen im Übrigen hiervon nicht berührt. Anstelle der unwirksamen Bestimmung soll eine Bestimmung treten, die im Rahmen des rechtlich Möglichen dem Willen der Parteien am nächsten kommt. Das Gleiche gilt im Falle einer Regelungslücke.</p>
            </div>
          </div>
        </div>
      </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
  </div>