<div class="page">
    <app-header></app-header>
    <!-- Swiper-->
    <section>
        <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
        data-loop="false" data-autoplay="5000">
        <div class="swiper-wrapper">
            <div class="swiper-slide" data-slide-bg="assets/images/page-ingenieure/Header.jpg">
            <div class="swiper-slide-caption text-left">
              <div class="container">
                <div class="row justify-content-start justify-content-xxl-start">
                  <div class="col-lg-10 col-xxl-6">
                    <h1 data-caption-animate="fadeInUpSmall">{{ 'ingeni.slider.title' | translate }}</h1>
                    <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">
                      {{ 'ingeni.slider.subtitle' | translate }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
  
      </div>
   
    </section>

    <!-- Orange line under slider -->
    <section class="section-xs bg-accent"></section>
    <!-- Impressive Features-->


    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
      <div class="section-md">
        <div class="container">
          <div class="row">

            <div class="col-md-6">
              <h4 class="heading-decorated">{{ 'ingeni.block1.title' | translate }}</h4>
              <p>{{ 'ingeni.block1.paraLeft.text1' | translate }}</p>
            </div>

            <div class="col-md-6">
              <h4 class="heading-decorated-blank">&nbsp;</h4>
              <p>{{ 'ingeni.block1.paraRight.text1' | translate }}<a
                class="orange" href="Virtual-Reality">{{ 'ingeni.block1.paraRight.orange1' | translate }}</a>{{ 'ingeni.block1.paraRight.text2' | translate }}<a
                class="orange" href="Augmented-Reality">{{ 'ingeni.block1.paraRight.orange2' | translate }}</a>{{ 'ingeni.block1.paraRight.text3' | translate }}<a
                href="Kontakt/Produktentwicklung/" class="orange">{{ 'ingeni.block1.paraRight.anchor' | translate }}</a>{{ 'ingeni.block1.paraRight.text4' | translate }}</p>
            </div>
          </div>
        </div>
      </div>

    </section>

    <!-- IHRE VORTEILE -->
    <section class="bg-default object-wrap">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
                <h4 class="heading-decorated">{{ 'ingeni.block2.title' | translate }}</h4>
                <ul class="c">
                  <li><p>&#9673;&nbsp;&nbsp;{{ 'ingeni.block2.paraLeft.text1' | translate }}</p></li>
                  <li><p>&#9673;&nbsp;&nbsp;{{ 'ingeni.block2.paraLeft.text2' | translate }}</p></li>
                  <li><p>&#9673;&nbsp;&nbsp;{{ 'ingeni.block2.paraLeft.text3' | translate }}</p></li>
                </ul>
            </div>
            <div class="col-lg-6">

              <div class="col-md-12">
                <!-- Post project-->
                <article class="post-project">

                  <figure>
                    <video controls autoplay="autoplay" loop  width="530" height="350">
                      <source src="assets/images/page-ingenieure/produktanimation-prozessanimation.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>

                  </figure>


                </article>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>


         <!--image block-->
         <section class="section-xs bg-default text-center">
          <div class="container">
            <div>&nbsp;</div>
              <img class="post-project__image" src="assets/images/Logo_Separator.png" alt="{{ 'home.SEO.alt.logo-sep' | translate }}" />
            
            <h4 class="orange">{{ 'ingeni.block3.title' | translate }}</h4>
            <div class="row">
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                      <img class="post-project__image" src="assets/images/page-ingenieure/ingenieurdienstleistung-cad-cae.jpg" alt="{{ 'ingeni.SEO.alt.thumb1' | translate }}" />
                      <span>&nbsp;</span>
                      <h5 class="orange">{{ 'ingeni.block3.item1.title' | translate }}</h5>
                    <p>{{ 'ingeni.block3.item1.desc' | translate }}</p>
                    <a href="#" class="orange">{{ 'ingeni.block3.anchor' | translate }}</a>
                  </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ingenieure/ingenieurdienstleistung-rendering-animation.jpg" alt="{{ 'ingeni.SEO.alt.thumb2' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ingeni.block3.item2.title' | translate }}</h5>
                    <p>{{ 'ingeni.block3.item2.desc' | translate }}</p>
                    <a href="#" class="orange">{{ 'ingeni.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ingenieure/ingenieurdienstleistung-ar-vr.jpg" alt="{{ 'ingeni.SEO.alt.thumb3' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ingeni.block3.item3.title' | translate }}</h5>
                    <p>{{ 'ingeni.block3.item3.desc' | translate }}</p>
                    <a href="#" class="orange">{{ 'ingeni.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                      <img class="post-project__image" src="assets/images/page-ingenieure/ingenieurdienstleistung-werkzeugbau.jpg" alt="{{ 'ingeni.SEO.alt.thumb4' | translate }}" />
                      <span>&nbsp;</span>
                      <h5 class="orange">{{ 'ingeni.block3.item4.title' | translate }}</h5>
                    <p>{{ 'ingeni.block3.item4.desc' | translate }}</p>
                    <a href="#" class="orange">{{ 'ingeni.block3.anchor' | translate }}</a>
                  </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ingenieure/ingenieurdienstleistung-rapid-prototyping.jpg" alt="{{ 'ingeni.SEO.alt.thumb5' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ingeni.block3.item5.title' | translate }}</h5>
                    <p>{{ 'ingeni.block3.item5.desc' | translate }}</p>
                    <a href="#" class="orange">{{ 'ingeni.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
              <div class="col-md-4 col-xl-4">
                <div class="item">
                  <figure>
                    <img class="post-project__image" src="assets/images/page-ingenieure/ingenieurdienstleistung-reverse-engineering.jpg" alt="{{ 'ingeni.SEO.alt.thumb6' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'ingeni.block3.item6.title' | translate }}</h5>
                    <p>{{ 'ingeni.block3.item6.desc' | translate }}</p>
                    <a href="#" class="orange">{{ 'ingeni.block3.anchor' | translate }}</a>
                </figure>
                </div>
              </div>
            </div>
            <h4>&nbsp;</h4><h4>&nbsp;</h4>
          </div>
          
        </section>
    



    <!-- Page Footer -->
    <app-footer></app-footer>
  </div>