<div class="page">
    <app-header></app-header>

  
    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
      <div class="section-md">
        <div class="container">
          <div class="row">

            <div class="col-md-12">
              <h5>§ 1 Subject matter of the contract / conclusion of the contract</h5>
              <p>(1) The contract is concluded between the contractor of 3Dscout GmbH, Parkstr. 10, 52372 Kreuzau (hereinafter: "Contractor") and the customer (hereinafter: "Customer") by acceptance of the offer submitted by the Customer. The validity of the conclusion of the contract presupposes the text form. The text of the contract shall be stored in compliance with the provisions of data protection law. The contract is concluded in English.</p>
              <p>(2) Previous offers are subject to change. Offers are valid for a period of 14 days. All agreements shall only become binding upon confirmation in text form, which shall always be deemed a commercial letter of confirmation if the customer is an entrepreneur. </p>
              <p>(3) The customer is a consumer within the terms of § 13 BGB (German Civil Code) insofar as the purpose of the ordered deliveries and services cannot be predominantly attributed to his commercial or self-employed professional activity. On the other hand, according to § 14 BGB (German Civil Code), an entrepreneur is any natural or legal person or partnership with legal capacity who, when concluding the contract, acts in the exercise of his commercial or self-employed professional activity.</p>
              <p>(4) The Contractor shall be entitled to make use of the services of third parties in order to fulfil the obligations arising from the contract without the Customer's consent being required.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 2 Scope of services</h5>
                <p>(1) The services to be rendered by the contractor shall be based on the individual agreements of the parties arising from the contractor's offer.</p>
                <p>(2) Services not agreed in advance are not covered by this agreement and the agreed remuneration. Such services must be ordered and remunerated separately.</p>
                <p>(3) Rights of use to external products are acquired by the customer independently and legally separate from the services offered. The license conditions of the respective manufacturer of the software or a protected work are to be observed.</p>
                <p>(4) With regard to the contents created by the Contractor, the Customer shall be granted a non-exclusive right of use that is unlimited in terms of time and content.</p>
                <p>(5) The Customer shall be responsible for providing any necessary playback software, unless otherwise agreed.</p>
                <p>(6) Unless otherwise agreed, the customer has no claim to the return of the raw material. The Contractor shall make the material available in a common file format, insofar as digital services are owed.</p>
                <p>(7) If printouts or data carriers (e.g. CD-ROM, memory card, USB stick) are sent by post, the customer bears the risk of dispatch if he is an entrepreneur.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 3 Warranty, guarantee</h5>
                <p>Insofar as the service rendered is a contract for work and services or the customer acquires goods, the following shall apply:</p>
                <p>(1) The Contractor shall be liable for defects in the work/item in accordance with the applicable statutory provisions, in particular §§ 434 et seq. of the German Civil Code (BGB) for goods and §§ 634 ff. BGB for works. For entrepreneurs, the warranty period for works/goods delivered by the contractor is 12 months. In the case of used goods, the warranty period shall be reduced to 12 months.</p>
                <p>(2) If the Customer is an entrepreneur, defects shall be notified to the Contractor in writing immediately, but at the latest within two weeks of delivery, in order to maintain the Customer's claims for defects. The defective works/goods shall be kept ready for inspection by the contractor in the condition in which they are at the time the defect is discovered. </p>
                <p>(3) In the case of objects produced with the 3D printer, no specific stability and durability is guaranteed. Due to the raw material for printing, the durability is limited. The contractor strongly advises against using 3D print models in safety-relevant areas.</p>
                <p>(4) An additional guarantee exists for the works/goods delivered by the contractor only if this was expressly given in the order confirmation for the respective article.</p>
            </div>  
            
            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 3a Reservation of title</h5>
                <p>(1) The delivered goods shall remain the property of the contractor until payment has been made in full.</p>
                <p>(2) The contractor reserves the right of ownership of the delivered goods vis-à-vis entrepreneurs until complete settlement of all claims arising from an ongoing business relationship.</p>
                <p>(3) The Customer shall be entitled to resell the reserved goods in the ordinary course of business provided that it is an entrepreneur and the Contractor has given its written consent. All resulting claims against third parties shall be prematurely assigned by the Customer to the Contractor in the amount of the full invoice value. The processing of the goods has no influence on the effectiveness of the assignment. However, the Customer shall remain entitled to collect its claims even after the assignment without affecting the Contractor's authority to collect the claims. The contractor sees no collection of the claims as long as no application for the opening of insolvency proceedings has been filed, and/or the customer fulfils his payment obligations to the contractor without any discount, and/or does not fall into arrears with payment.</p>

            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 4 Obligations of the Customer</h5>
                <p>(1) The customer undertakes to cooperate in the fulfilment of the contract in such a way that the contractor can carry out the work smoothly. In particular, all information required for the performance of the service shall be transmitted to the Contractor truthfully and completely. Damages due to inadequate information shall be borne by the Customer (reference is made to § 6).</p>
                <p>(2) The customer himself is responsible for compliance with data protection and personal rights regulations. The customer assures to obtain any necessary consents. The Customer shall indemnify the Contractor against any claims asserted against it by third parties due to the infringement of such rights.</p>
                <p>(3) The Customer shall be responsible for compliance with statutory provisions after receipt of the service/goods. This also applies in particular to the legal requirements in certain countries. The examination for legal conformity is not the subject of the owed service, unless expressly agreed.</p>
                <p>(4) If errors occur during the use of the subject matter of the contract, the Customer shall be obliged to notify the Contractor immediately in writing and to provide the relevant information for the correction of the error. This includes in particular the listing of the sequence of program sequences, the reproduction of the activities carried out with the subject matter of the contract, etc., as well as the execution of the order. At the Contractor's request, the Customer shall record the reported error and make this documentation available to the Contractor.</p>
                <p>(5) The customer assures that the contents transmitted by him are free of rights of third parties and indemnifies the contractor from any claims in this regard.</p>
                <p>(6) The customer is obliged to check the services performed by the contractor and to carry out any necessary validations.</p>
                <p>(7) If the Customer violates the obligations, the Contractor shall be entitled to invoice the additional costs incurred (e.g. additional labour costs).</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 5 Remuneration and payment processing</h5>
                <p>(1) The contractor shall invoice his services on the basis of a lump-sum fee, unless otherwise agreed. Work on weekends and public holidays shall only be performed by agreement of the parties. The agreed prices apply per employee, unless otherwise agreed.</p>
                <p>(2) The contractor shall demand a down payment of 50% of the order sum upon conclusion of the contract. The remaining amount is due upon completion. If goods are purchased, the complete amount is due with the term of payment stated on the invoice, unless otherwise agreed.</p>
                <p>(3) The payment method depends on the agreements of the parties.</p>
                <p>(4) If a payment by invoice has been agreed and if the due date of the payment is determined according to the calendar, the customer is already in default due to failure to meet the deadline. In this case he shall pay the Contractor default interest for the year in the amount of 5 percentage points above the base interest rate. If the Customer is an entrepreneur, the default interest shall amount to 9 percentage points above the base interest rate. The Customer's obligation to pay interest on arrears shall not preclude the Contractor from asserting further claims for damages caused by default.</p>
                <p>(5) The contractor is entitled to withdraw from the contract if the customer is in default.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 5a Prevention of the contractor</h5>
                <p>(1) If the Contractor is prevented from rendering services due to illness or force majeure, the agreed delivery time/performance period shall be extended by the duration of the hindrance. A claim for damages does not exist here.</p>
                <p>(2) The contractor is not obliged but entitled to have the service rendered by a third party during the hindrance. In the event of performance by a third party, the Customer shall not incur any additional costs. The agreed invoice of the parties shall apply.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 5b Termination, Cancellation</h5>
                <p>(1) The right to ordinary termination is excluded. The right to extraordinary termination due to an important reason remains unaffected.</p>
                <p>(2) The termination requires text form to be effective.</p>
                <p>(3) Decisive for the time of termination is the receipt of the notice of termination by the recipient.</p>
                <p>(4) A cancellation of the contract is excluded. The customer's right of revocation remains unaffected if the customer is a consumer.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 6 Liability</h5>
                <p>(1) Claims of the customer for damages are excluded. Excluded from this are claims for damages by the Customer arising from injury to life, limb or health or from the breach of essential contractual obligations (cardinal obligations) as well as liability for other damages based on an intentional or grossly negligent breach of duty by the Contractor, its legal representatives or vicarious agents. Material contractual obligations are those whose fulfilment is necessary to achieve the objective of the contract.</p>
                <p>(2) In the event of a breach of essential contractual obligations, the Contractor shall only be liable for the foreseeable damage typical of the contract if this was caused by simple negligence, unless it is a matter of the Customer's claims for damages arising from injury to life, limb or health.</p>
                <p>(3) Liability for loss of profit vis-à-vis entrepreneurs is excluded. Paragraph 1 remains unaffected.</p>
                <p>(4) The restrictions shall also apply in favour of the Contractor's legal representatives and vicarious agents if claims are asserted directly against them.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 7 Confidentiality</h5>
                <p>(1) "Confidential information" means all information and documents relating to transactions of the other party concerned which come to the knowledge of the other party.</p>
                <p>(2) Both parties undertake to maintain secrecy with regard to confidential information concerning the other party and to use such information only for the execution of this contract and the purpose pursued thereby.</p>
                <p>(3) Both parties undertake to impose the duty of confidentiality on all employees and/or third parties who have access to the aforementioned business transactions. </p>
                <p>(4) The obligation to maintain secrecy pursuant to para. 2 shall not apply to information,</p>
                <ul class="padding-left-50">
                        <li>a) which were already known to the other party when the contract was concluded,</li>
                        <li>b) which were already published at the time of disclosure by the Contractor, without this resulting from a breach of confidentiality by the respective other party,</li>
                        <li>(c) which the other party has expressly authorised to be transferred in writing,</li>
                        <li>(d) which the other party has lawfully received from other sources, without restriction as to confidentiality, from any other source, provided that the disclosure and use of such Confidential Information does not breach any contractual agreement, regulation or administrative order,</li>
                        <li>(e) developed by the other party itself without access to the confidential information of the contracting authority,</li>
                        <li>f) which must be disclosed due to legal information, information and/or publication obligations or official orders. To the extent permissible, the party obliged to do so shall inform the other party as soon as possible and support it as best as possible in taking action against the disclosure obligation.</li>
                </ul>
                <p>(5) Reference is made to a confidentiality agreement to be concluded separately, if applicable. This shall become part of the contract. Insofar as this deviates from § 7, it takes precedence over the GTC in this point.</p> 
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 8 Data protection</h5>
                <p>(1) The Customer agrees to the storage of personal data within the scope of the business relationship with the Contractor, in compliance with the data protection laws, in particular the BDSG (German data protection law) and the GDPR. Data shall not be passed on to third parties unless this is necessary for the execution of the contract or consent has been given.</p>
                <p>(2) Insofar as the Contractor has to process personal data of third parties (e.g. employees, customers) during the execution of the contract, the Customer assures that he has obtained the consent of the parties concerned and indemnifies the Contractor against any claims in this respect.</p>
                <p>(3) If remote maintenance has been agreed between the parties, the Customer declares his consent, which can be revoked at any time, according to which the Contractor can dial into his computer system in consultation with and in the presence of the Customer in order to carry out maintenance.</p>
                <p>(4) The rights of the Customer or the person concerned are derived in particular from the following standards of the GDPR:</p>
                    <ul class="padding-left-50">
                        <li>Article 7 (3) - Right to revoke consent under data protection law</li>
                        <li>Article 15 - Data subject's right of access, confirmation and provision of a copy of personal data</li>
                        <li>Article 16 - Right to rectification</li>
                        <li>Article 17 - Right to cancellation ("right to be forgotten")</li>
                        <li>Article 18 - Right to limitation of processing</li>
                        <li>Article 20 - Right to data transferability</li>
                        <li>Article 21 - Right of appeal</li>
                        <li>Article 22 - Right not to be subject to a decision based exclusively on automated processing, including profiling</li>
                        <li>Article 77 - Right to complain to a supervisory authority</li>
                    </ul>
                <p>(5) In order to exercise the rights, the customer or the person concerned is requested to contact the contractor by e-mail or, in the event of a complaint, the competent supervisory authority.</p>
                <p>(6) The contractor assures to have taken technical-organizational measures to ensure the protection of personal data. Otherwise, reference is made to the Contractor's data protection declaration.</p>
                <p>(7) The customer expressly agrees that object-related data may be transferred to third countries outside the European Union for the execution of the contract. However, this does not affect personal data.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 9 Reference name/labelling</h5>
                <p>(1) The contractor is entitled to indicate the customer on the website and in social networks free of charge as a reference, unless otherwise expressly agreed. </p>
                <p>(2) The contractor is entitled to place a reference to the contractor's company on created content. This also includes the embedding of a link to the Contractor's website, if possible. A deviating agreement requires the text form.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 10 Settlement of disputes</h5>
                <p>(1) The EU platform for out-of-court online dispute resolution is available at the following Internet address:
                    <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
                <p>(2) The supplier shall neither be willing nor obliged to participate in any dispute settlement procedure before a consumer redress body.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>§ 11 Final provisions</h5>
                <p>(1) The business relations between the parties are subject to the law of the Federal Republic of Germany to the exclusion of the UN Convention on Contracts for the International Sale of Goods. </p>
                <p>(2) The place of jurisdiction and performance shall be the Contractor's registered office in Kreuzau (NRW, Germany), insofar as the Customer is a merchant within the meaning of the German Commercial Code (HGB) or a legal entity under public law or a special fund under public law. The same applies if the customer does not have a general place of jurisdiction in Germany or if his place of residence or habitual abode is not known at the time the action is filed.</p>
                <p>(3) With the conclusion of the contract, the customer declares his agreement with the valid general terms and conditions. </p>
                <p>(4) There are no subsidiary agreements to the contract. Insofar as collateral agreements are agreed, they must be in text form in order to be effective. Agreements in written offers of the parties take precedence over these General Terms and Conditions if contradictions to the General Terms and Conditions exist.</p>
                <p>(5) Should any provision of these General Terms and Conditions be or become invalid, the validity of the remaining General Terms and Conditions shall not be affected thereby. The invalid provision shall be replaced by a provision that comes as close as possible to the will of the parties within the framework of what is legally possible. The same shall apply in the event of a loophole.</p>
            </div>
          </div>
        </div>
      </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
  </div>