<div class="page">
    <app-header></app-header>

  
    <!-- Widerruf -->
    <section class="section-xs bg-default object-wrap">
      <div class="section-md">
        <div class="container">
          <div class="row">

            <div class="col-md-12">
              <h5>Right of revocation</h5>
              <p>(1) When concluding a distance selling transaction, consumers generally have a statutory right of revocation, about which the provider informs them in the following in accordance with the statutory model. The exceptions to the right of withdrawal are regulated in paragraph (2). Paragraph (3) contains a model revocation form.</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>Revocation instruction</h5>
                <p>Right of revocation</p>
                <p>You have the right to revoke this contract within fourteen days without giving reasons. The revocation period is fourteen days from the date of conclusion of the contract for services and fourteen days from receipt of the goods for the purchase of goods.</p>
                <p>In order to exercise your right of withdrawal, you must inform us of your decision to withdraw from this contract by means of a clear statement (e.g. a letter sent by post, telephone call or e-mail). You can use the attached sample revocation form, which is not mandatory.</p>
                <p>In order to comply with the revocation period, it is sufficient for you to send the notification of exercising the right of revocation before the expiry of the revocation period.</p>
                <p>&nbsp;</p>
                <p>Consequences of Revocation when booking a Service / Work Performance</p>
                <p>If you revoke this Agreement, we shall repay to you all payments received from you immediately and no later than fourteen days from the date on which we received notice of your revocation of this Agreement. We will use the same means of payment used by you in the original transaction for such refund, unless expressly agreed otherwise with you and in no event will you be charged for such refund.</p>
                <p>Consequences of the revocation with the purchase of goods</p>
                <p>If you revoke this Agreement, we shall reimburse you immediately and no later than fourteen days from the date on which we received notice of your revocation of this Agreement for all payments we have received from you, including delivery charges (other than additional charges arising from your choice of a different method of delivery from the standard, low-cost delivery offered by us). Such refund will be made using the same means of payment that you used in the original transaction, unless expressly agreed otherwise with you and in no event will you be charged for such refund.</p>
                <p>We may refuse to refund until we have received the Goods back or until you have provided evidence that you have returned the Goods, whichever is earlier.</p>
                <p>You must return or hand over the goods to us immediately and in any case no later than fourteen days after the day on which you notify us of the revocation of this contract. This period shall be deemed to have been observed if you dispatch the goods before the expiry of the period of fourteen days.</p>
                <p>You shall bear the direct costs of returning the goods.</p>
                <p>You shall only be liable for any loss in value of the goods if such loss in value is due to handling of the goods that is not necessary for testing their condition, properties and functionality. </p>
                <p>&nbsp;</p>
                <p>(2) The right of withdrawal shall not apply to contracts for the supply of goods which are not prefabricated and for the manufacture of which an individual choice or destination by the consumer is decisive or which are clearly tailored to the personal needs of the consumer.</p>
                <p>The right of withdrawal does not apply to contracts for the delivery of sound or video recordings or computer software in a sealed package if the seal has been removed after delivery.</p>
                <p>The right of revocation expires in the case of a contract for the delivery of digital content not on a physical data carrier even if the entrepreneur has begun the execution of the contract after the consumer has expressly agreed that the entrepreneur begins the execution of the contract before the expiry of the revocation period, and has confirmed his knowledge of it that he loses his right of revocation through his consent with the beginning of the execution of the contract.</p>
                <p>In the case of a contract for the provision of services, the right of revocation shall also lapse if the entrepreneur has completely provided the service and has only begun to provide the service after the consumer has given his express consent to this and at the same time has confirmed his knowledge that he will lose his right of revocation if the entrepreneur has completely fulfilled the contract. In the case of a contract concluded away from business premises, the consumer's consent must be transmitted on a durable medium.</p>
                <p>(3) The provider shall provide the following information on the sample revocation form in accordance with the statutory provisions:</p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <h5>Sample revocation form</h5>
                <p>(If you want to cancel the contract, please fill in this form.
                    and send it back.)</p>
                <p>&nbsp;</p>
                <p>An:<br>3Dscout GmbH<br>Parkstr. 10<br>D-52372 Kreuzau</p>
                <p>&nbsp;</p>
                <p>Or by Email: info@3dscout.de</p>
            </div>  
            
            <div class="col-md-12">
                <p>&nbsp;</p>
                <p>— I/we (*) hereby cancel the contract concluded by me/us (*)
                        <br>concerning the purchase of the following services / goods (*)/
                        <br>— Ordered on (*)/received on (*)
                        <br>— Name(s) of consumer(s)
                        <br>— Address of consumer(s)
                        <br>— Signature of consumer(s) (only for paper communication)
                        <br>— Date
                        </p>
                <p>&nbsp;</p>
                <p>(*) Delete where not applicable. </p>
            </div>

            <div class="col-md-12">
                <p>&nbsp;</p>
                <p>The revocation can also be declared by telephone:</p>
                <p>Phone: +49 2421 2997301</p>
            </div>

          </div>
        </div>
      </div>

    </section>



    <!-- Page Footer -->
    <app-footer></app-footer>
  </div>