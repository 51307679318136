<div class="page">
    <app-header></app-header>
    <!-- Swiper-->
    <section>

        <div class="swiper-container swiper-slider swiper-slider_fullheight bg-gray-dark" data-simulate-touch="false"
        data-loop="false" data-autoplay="5000">
        <div class="swiper-wrapper">
            <div class="swiper-slide" data-slide-bg="assets/images/page-vr/Header.jpg">
            <div class="swiper-slide-caption text-left">
              <div class="container">
                <div class="row justify-content-start justify-content-xxl-end">
                  <div class="col-lg-10 col-xxl-6">
                    <h1 data-caption-animate="fadeInUpSmall">{{ 'vr.slider.title' | translate }}</h1>
                    <h5 class="font-weight-normal" data-caption-animate="fadeInUpSmall" data-caption-delay="200">
                      {{ 'vr.slider.subtitle' | translate }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
  
      </div>

    </section>

    <!-- Orange line under slider -->
    <section class="section-xs bg-accent"></section>
    <!-- Impressive Features-->


    <!-- Augmented Reality -->
    <section class="section-xs bg-default object-wrap">
      <div class="section-md">
        <div class="container">
            <div class="row">

                <div class="col-md-6">
                  <h4 class="heading-decorated">{{ 'vr.block1.title' | translate }}</h4>
                  <p>{{ 'vr.block1.paraLeft.text1' | translate }}<a
                    class="orange" href="Produktentwicklung">{{ 'vr.block1.paraLeft.orange1' | translate }}</a>{{ 'vr.block1.paraLeft.text2' | translate }}<a
                    class="orange" href="Produktentwicklung">{{ 'vr.block1.paraLeft.orange2' | translate }}</a>{{ 'vr.block1.paraLeft.text3' | translate }}</p>
                </div>
    
                <div class="col-md-6">
                  <h4 class="heading-decorated-blank">&nbsp;</h4>
                  <p>{{ 'vr.block1.paraRight.text1' | translate }}<a
                    href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block1.paraRight.anchor' | translate }}</a></p>
                </div>
              </div>
        </div>
      </div>

    </section>

    <!-- IHRE VORTEILE -->
    <section class="bg-default object-wrap">
      <div class="">
        <div class="container">
          <div class="row">
              <div class="col-lg-6">
                  <h4 class="heading-decorated">{{ 'vr.block2.title' | translate }}</h4>
                  <ul class="c">
                    <li><p>&#9673;&nbsp;&nbsp;{{ 'vr.block2.paraLeft.text1' | translate }}</p></li>
                    <li><p>&#9673;&nbsp;&nbsp;{{ 'vr.block2.paraLeft.text2' | translate }}</p></li>
                    <li><p>&#9673;&nbsp;&nbsp;{{ 'vr.block2.paraLeft.text3' | translate }}</p></li>
                  </ul>
                </div>
            <div class="col-lg-6">

              <div class="col-md-12">
                <!-- Post project-->
                <article class="post-project">

                  <figure>
                    <video controls autoplay="autoplay" loop  width="530" height="350">
                      <source src="assets/images/page-vr/virtual-reality-3dscout.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>

                  </figure>


                </article>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>


     <!--image block-->
     <section class="section-xs bg-default text-center">
        <div class="container">
          <div>&nbsp;</div>
            <img class="post-project__image" src="assets/images/Logo_Separator.png" alt="{{ 'home.SEO.alt.logo-sep' | translate }}" />
          
          <h4 class="orange">{{ 'vr.block3.title' | translate }}</h4>
          <div class="row">
            <div class="col-md-4 col-xl-4">
              <div class="item">
                <figure>
                    <img class="post-project__image" src="assets/images/page-vr/virtual-reality-immobilienplanung.jpg" alt="{{ 'vr.SEO.alt.thumb1' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'vr.block3.item1.title' | translate }}</h5>
                    <p>{{ 'vr.block3.item1.desc' | translate }}</p>
                    <a href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block3.anchor' | translate }}</a>
                </figure>
              </div>
            </div>
            <div class="col-md-4 col-xl-4">
              <div class="item">
                <figure>
                  <img class="post-project__image" src="assets/images/page-vr/virtual-reality-raumplanung.jpg" alt="{{ 'vr.SEO.alt.thumb2' | translate }}" />
                  <span>&nbsp;</span>
                  <h5 class="orange">{{ 'vr.block3.item2.title' | translate }}</h5>
                    <p>{{ 'vr.block3.item2.desc' | translate }}</p>
                    <a href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block3.anchor' | translate }}</a>
              </figure>
              </div>
            </div>
            <div class="col-md-4 col-xl-4">
              <div class="item">
                <figure>
                  <img class="post-project__image" src="assets/images/page-vr/virtual-reality-produktvisualisierung.jpg" alt="{{ 'vr.SEO.alt.thumb3' | translate }}" />
                  <span>&nbsp;</span>
                  <h5 class="orange">{{ 'vr.block3.item3.title' | translate }}</h5>
                    <p>{{ 'vr.block3.item3.desc' | translate }}</p>
                    <a href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block3.anchor' | translate }}</a>
              </figure>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-xl-4">
              <div class="item">
                <figure>
                    <img class="post-project__image" src="assets/images/page-vr/virtual-reality-marketing-vertrieb.jpg" alt="{{ 'vr.SEO.alt.thumb4' | translate }}" />
                    <span>&nbsp;</span>
                    <h5 class="orange">{{ 'vr.block3.item4.title' | translate }}</h5>
                    <p>{{ 'vr.block3.item4.desc' | translate }}</p>
                    <a href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block3.anchor' | translate }}</a>
                </figure>
              </div>
            </div>
            <div class="col-md-4 col-xl-4">
              <div class="item">
                <figure>
                  <img class="post-project__image" src="assets/images/page-vr/virtual-reality-virtuelle-filiale.jpg" alt="{{ 'vr.SEO.alt.thumb5' | translate }}" />
                  <span>&nbsp;</span>
                  <h5 class="orange">{{ 'vr.block3.item5.title' | translate }}</h5>
                    <p>{{ 'vr.block3.item5.desc' | translate }}</p>
                    <a href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block3.anchor' | translate }}</a>
              </figure>
              </div>
            </div>
            <div class="col-md-4 col-xl-4">
              <div class="item">
                <figure>
                  <img class="post-project__image" src="assets/images/page-vr/virtual-reality-e-training.jpg" alt="{{ 'vr.SEO.alt.thumb6' | translate }}" />
                  <span>&nbsp;</span>
                  <h5 class="orange">{{ 'vr.block3.item6.title' | translate }}</h5>
                    <p>{{ 'vr.block3.item6.desc' | translate }}</p>
                    <a href="Kontakt/Virtual-Reality/" class="orange">{{ 'vr.block3.anchor' | translate }}</a>
              </figure>
              </div>
            </div>
          </div>
          <h4>&nbsp;</h4><h4>&nbsp;</h4>
        </div>
        
      </section>




    <!-- Page Footer -->
    <app-footer></app-footer>
  </div>