<!-- Page Footer -->
<footer class="footer">
    
    <div class="contentwrap">

      <div class="footer-contactarea innerwrap">
          <div class="col-md-2">
              <div class="footer-contactarea__img">
                  <img src="assets/images/make-in-india-hilfe-in-deutschland.jpg" alt="{{ 'home.SEO.alt.lady' | translate }}">
                </div>
          </div>
          <div class="col-md-2">
              <ul class="footer-contactarea__circles">
                  <li>
                    <a href="Kontakt/" class="circle-link">
                      <div class="circle-link__circle1">
                        <img src="assets/images/footer/choice.png" height="64" width="64" alt="{{ 'home.SEO.alt.lady' | translate }}"/>
                      </div>
                    </a>
                  </li>
                </ul>
                <p class="figure-centered">{{ 'footer.form' | translate }}</p>
          </div>

          <div class="col-md-3">
              <ul class="footer-contactarea__circles">
                  <li>
                    <a href="tel:004924212997301" class="circle-link">
                      <div class="circle-link__circle1">
                        <img src="assets/images/footer/call.png" height="64" width="64" alt="{{ 'home.SEO.alt.lady' | translate }}"/>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@3dscout.de?subject=User%20Request" class="circle-link">
                      <div class="circle-link__circle1">
                        <img src="assets/images/footer/email.png" height="64" width="64" alt="{{ 'home.SEO.alt.lady' | translate }}"/>
                      </div>
                    </a>
                  </li>
                  
                </ul>
                <p class="figure-centered">{{ 'footer.contact' | translate }}</p>
          </div>
        
          <div class="col-md-5">
              
              <ul class="footer-contactarea__circles">
                  <li>
                    <a href="https://www.xing.com/companies/3dscoutgmbh" target="_blank" class="circle-link">
                      <div class="circle-link__circle1">
                        <img src="assets/images/footer/xing.png" height="64" width="64" alt="Xing"/>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/3dscout-gmbh" target="_blank" class="circle-link">
                      <div class="circle-link__circle1">
                        <img src="assets/images/footer/linkedin.png" height="64" width="64" alt="Linkedin"/>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/3Dscout-GmbH-107713757515808/" target="_blank" class="circle-link">
                      <div class="circle-link__circle1">
                        <img src="assets/images/footer/facebook.png" height="64" width="64" alt="Facebook"/>
                      </div>
                    </a>
                  </li>

                </ul>
                <p class="figure-centered">{{ 'footer.social' | translate }}</p>
          </div>

<!--         <div class="footer-contactarea__text fw-medium">
          <p>Erreichen Sie uns unter</p>

          <a href="tel:06913649907" class="">
            <span class="footer-icon-phone-svg"></span>
            <span style="vertical-align: 4px; font-weight:300;">&nbsp;089 517 909 02</span>
          </a>
          <br>
          <a href="mailto:info@3dscout.de?subject=Hello%20again" class="">
            <span class="footer-icon-email-svg"></span>
            <span style="vertical-align: 4px; font-weight:300;">&nbsp;info@3dscout.de</span>
          </a>

        </div> -->
      
      </div>

      <div class="footer-meta innerwrap">
        <nav class="nav footer-meta__metalinks">
          <ul>

            <li>
              <a href="{{ menuimpressum }}/">{{ 'footer.impressum' | translate }}</a>
            </li>
            <li>
              <a href="{{ menuagb }}/">{{ 'footer.agb' | translate }}</a>
            </li>
            <li>
              <a href="{{ menuwiderruf }}/">{{ 'footer.widerruf' | translate }}</a>
            </li>
            <li>
              <a href="{{ menudatenschutz }}/">{{ 'footer.datenschutz' | translate }}</a>
            </li>
          </ul>
        </nav>
        <nav class="foo-nav">
          <ul>
            <li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li>
            <li>{{ 'footer.footnote.text1' | translate }} <a class="text-underline" href="{{ 'path.datenschutz' | translate }}/">{{ 'footer.footnote.link' | translate }}</a> {{ 'footer.footnote.text2' | translate }}</li>
          </ul>

        </nav>
      </div>
    </div>
  </footer>