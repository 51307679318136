import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CookService } from '../services/cook.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {

  constructor(public translate:TranslateService, public cookie: CookService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.url
      .subscribe(params => {
        let path:string = params[0].path;
        if(path == 'en'){
          this.translate.use('en');
          this.cookie.setCookie('lang','en');
        }else{
          this.translate.use('de');
          this.cookie.setCookie('lang','de');
        }
      });



  }

}
