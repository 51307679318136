import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookService } from '../services/cook.service';

@Component({
  selector: 'app-sub-ar',
  templateUrl: './sub-ar.component.html',
  styleUrls: ['./sub-ar.component.css']
})
export class SubArComponent implements OnInit {

  pagetitle: any;
  desc: string;
  constructor(public translate: TranslateService, public cookie: CookService, private route: ActivatedRoute, private meta: Meta, private title: Title) { }
  ngOnInit(): void {

    this.route.url
.subscribe(params => {
  let path:string = params[0].path;
  if(path == 'en'){
    this.translate.use('en');
    this.cookie.setCookie('lang','en');
  }else{
    this.translate.use('de');
    this.cookie.setCookie('lang','de');
  }
});

    this.translate.get('ar.SEO.title').subscribe((res: any) => {
      this.pagetitle = res;
      this.title.setTitle(this.pagetitle);
    });

    this.translate.get('ar.SEO.desc').subscribe((res: any) => {
      this.desc = res;
      this.meta.updateTag({ name: 'description', content: this.desc });
    });
  }

}
