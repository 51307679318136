<router-outlet></router-outlet>
<!--   <div class="box">
    <a class="button" href="#popup1">Let me Pop up</a>
  </div> -->

<!-- <div id="covid19" class="overlay">
    <div class="popup">
        <div class="popup-inner">
            <img src="../assets/images/covid19.jpg" alt="Bild von 3dscout.de">
            <span>&nbsp;</span>
            <h2>{{ 'home.covid19.title' | translate }}</h2>
            <span>&nbsp;</span>
            <a class="close" href="#">&times;</a>
            <div class="content">
                {{ 'home.covid19.subtitle' | translate }}
            </div>
            <span>&nbsp;</span>
            <div style="margin: auto; width:fit-content; padding-bottom: 10px;">
                <a class="button button-primary" href="Kontakt/">{{ 'home.cloudBanner.button' | translate }}</a>
            </div>
            
        </div>

    </div>
</div> -->