import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Sub3dDruckComponent } from './sub3d-druck/sub3d-druck.component';
import { SubKleinserienfertigungComponent } from './sub-kleinserienfertigung/sub-kleinserienfertigung.component';
import { SubArComponent } from './sub-ar/sub-ar.component';
import { SubVrComponent } from './sub-vr/sub-vr.component';
import { SubIngeniComponent } from './sub-ingeni/sub-ingeni.component';
import { SubArchiComponent } from './sub-archi/sub-archi.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { CareerComponent } from './career/career.component';
import { ImpressumComponent } from './sub-legal/impressum/impressum.component';
import { AgbComponent } from './sub-legal/agb/agb.component';
import { WiderrufComponent } from './sub-legal/widerruf/widerruf.component';
import { DatenschutzComponent } from './sub-legal/datenschutz/datenschutz.component';
import { GTCComponent } from './sub-legal/gtc/gtc.component';
import { LegalnoticeComponent } from './sub-legal/legalnotice/legalnotice.component';
import { RevocationComponent } from './sub-legal/revocation/revocation.component';
import { DataPrivacyComponent } from './sub-legal/data-privacy/data-privacy.component';
import { HauptseiteComponent } from './home/hauptseite/hauptseite.component';


const routes: Routes = [
  { path: '', component: HauptseiteComponent },
  { path: 'en', component: HomeComponent },
  // { path: 'covid19', redirectTo: '/#covid19', pathMatch: 'full' },
  { path: '3d-druck', component: Sub3dDruckComponent },
  { path: 'en/3d-print', component: Sub3dDruckComponent },
  { path: 'Auftragsfertigung', component: SubKleinserienfertigungComponent },
  { path: 'en/Contract-Manufacturing', component: SubKleinserienfertigungComponent },
  { path: 'Erweiterte-Realitaet', component: SubArComponent },
  { path: 'en/Augmented-Reality', component: SubArComponent },
  { path: 'Virtuelle-Realitaet', component: SubVrComponent },
  { path: 'en/Virtual-Reality', component: SubVrComponent },
  { path: 'Architekturleistungen', component: SubArchiComponent },
  { path: 'en/Architecture-Development', component: SubArchiComponent },
  { path: 'Produktentwicklung', component: SubIngeniComponent },
  { path: 'en/Product-Development', component: SubIngeniComponent },
  { path: 'Kontakt/:ref', component: KontaktComponent },
  { path: 'Kontakt', component: KontaktComponent },
  { path: 'en/Contact/:ref', component: KontaktComponent },
  { path: 'en/Contact', component: KontaktComponent },
  { path: 'Karriere', component: CareerComponent },
  { path: 'en/Career', component: CareerComponent },
  { path: 'Impressum', component: ImpressumComponent },
  { path: 'en/Legal-Notice', component: LegalnoticeComponent },
  { path: 'AGB', component: AgbComponent },
  { path: 'en/GTC', component: GTCComponent },
  { path: 'Widerruf', component: WiderrufComponent },
  { path: 'en/Revocation', component: RevocationComponent },
  { path: 'Datenschutz', component: DatenschutzComponent },
  { path: 'en/Data-Privacy', component: DataPrivacyComponent },
  { path: '**', redirectTo: '' }
];

const route_en: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'covid19', redirectTo: '/#covid19', pathMatch: 'full' },
  { path: 'en/3d-print', component: Sub3dDruckComponent },
  { path: 'en/Contract-Manufacturing', component: SubKleinserienfertigungComponent },
  { path: 'en/Augmented-Reality', component: SubArComponent },
  { path: 'en/Virtual-Reality', component: SubVrComponent },
  { path: 'en/Architecture-Development', component: SubArchiComponent },
  { path: 'en/Product-Development', component: SubIngeniComponent },
  { path: 'en/Contact/:ref', component: KontaktComponent },
  { path: 'en/Contact', component: KontaktComponent },
  { path: 'en/Career', component: CareerComponent },
  { path: 'en/Legal-Notice', component: LegalnoticeComponent },
  { path: 'en/GTC', component: GTCComponent },
  { path: 'en/Revocation', component: RevocationComponent },
  { path: 'en/Data-Privacy', component: DataPrivacyComponent },
  { path: '**', redirectTo: '' }
];

const route_de: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'covid19', redirectTo: '/#covid19', pathMatch: 'full' },
  { path: '3d-druck', component: Sub3dDruckComponent },
  { path: 'Auftragsfertigung', component: SubKleinserienfertigungComponent },
  { path: 'Erweiterte-Realitaet', component: SubArComponent },
  { path: 'Virtuelle-Realitaet', component: SubVrComponent },
  { path: 'Architekturleistungen', component: SubArchiComponent },
  { path: 'Produktentwicklung', component: SubIngeniComponent },
  { path: 'Kontakt/:ref', component: KontaktComponent },
  { path: 'Kontakt', component: KontaktComponent },
  { path: 'Karriere', component: CareerComponent },
  { path: 'Impressum', component: ImpressumComponent },
  { path: 'AGB', component: AgbComponent },
  { path: 'Widerruf', component: WiderrufComponent },
  { path: 'Datenschutz', component: DatenschutzComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
