import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookService } from '../services/cook.service';

@Component({
  selector: 'app-sub3d-druck',
  templateUrl: './sub3d-druck.component.html',
  styleUrls: ['./sub3d-druck.component.css']
})
export class Sub3dDruckComponent implements OnInit {
  pagetitle: any;
  desc: string;
  constructor(public translate: TranslateService, public cookie: CookService, private route: ActivatedRoute, private meta: Meta, private title: Title) { }

  ngOnInit(): void {

    this.route.url
      .subscribe(params => {
        let path: string = params[0].path;
        if (path == 'en') {
          this.translate.use('en');
          this.cookie.setCookie('lang', 'en');
        } else {
          this.translate.use('de');
          this.cookie.setCookie('lang', 'de');
        }
      });

    this.translate.get('3d.SEO.title').subscribe((res: any) => {
      this.pagetitle = res;
      this.title.setTitle(this.pagetitle);
    });

    this.translate.get('3d.SEO.desc').subscribe((res: any) => {
      this.desc = res;
      this.meta.updateTag({ name: 'description', content: this.desc });
    });



  }

}
