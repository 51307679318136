import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookService {
  private cookieValue:string;
  constructor(private cookieService: CookieService) { }

  public getCookie(): string{
    if(this.cookieService.check('lang')){
      return this.cookieService.get('lang');
    }else{
      return '##'
    }
   }

   public checkCookie(cookie:string):boolean{
    if(this.cookieService.check(cookie)){
      return true;
    }else{
      return false;
    }
   }

   public deleteCokie(cookie:string){
    this.cookieService.delete(cookie);
   }

   public setCookie(cookie:string, lang:string){
     this.deleteCokie(cookie);
      this.cookieService.set(cookie,lang,30,'');
   }
}
